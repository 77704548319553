import { isTrue } from '@/utils/Common/Booleans';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import { renderWishlistIconPortal } from '@/components/organisms/MiniWishlistBraun';
import BraunUserController from '@/braun/Controllers/BraunUserController';
import { renderCartIconPortal } from '@/components/organisms/MiniCartBraun';
import NavigationMobileController from '@/braun/Controllers/Header/NavigationMobileController';
import NavigationDesktopController from '@/braun/Controllers/Header/NavigationDesktopController';
import initOnScrollBehaviour from '@/braun/Controllers/Header/ScrollBehaviour';

const HEADER_SELECTOR = '#js-braun-header';
const CORPORATE_CLASS = '--has-corporate-stripe';

const corporateCheck = header => {
  if (header.classList.contains(CORPORATE_CLASS)) {
    const headerRect = header.getBoundingClientRect();
    document.body.style.setProperty(
      '--headerHeight',
      `${headerRect.height + 20}px`,
    );
  }
};

const showHeader = header => {
  document.body.classList.add('headerReady');
  if (header) {
    corporateCheck(header);
    header.style.opacity = 1;
  }
};

const initHeader = () => {
  const entries = SmarteditController.getEditorialEntries(HEADER_SELECTOR);
  if (!entries[0] || isTrue(DLG.config.liteHeaderFooter)) {
    showHeader(entries[0]);
    initOnScrollBehaviour(entries[0]);
    return;
  }

  // init cart icon component
  renderCartIconPortal();

  // init wishlist icon component
  renderWishlistIconPortal();

  // init account drawer
  BraunUserController();

  // search modal init on shared controllers

  // init scroll behaviour
  initOnScrollBehaviour(entries[0]);

  // init desktop Navigation
  NavigationDesktopController();

  // init mobile Navigation
  NavigationMobileController();

  showHeader(entries[0]);
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initHeader);
  initHeader();
};
