/* eslint-disable */
import { initCarousel } from '@/braun/Controllers/BraunCarouselController';
import { toggleClasses } from '@/utils/Common/Utils';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

let TimelineLite;
let Power0;
let gsap;
let CSSPlugin;

export default () => {
  const globalOpenCloseDuration = 0.6;

  class Gallery {
    constructor(el) {
      this.DOM = { el: el };
      this.DOM.braGalleries = this.DOM.el.querySelectorAll(
        '.bra-gallery__wrapper',
      );

      let braGalleries_length = this.DOM.braGalleries.length;
      this.DOM.swiperGalleryActive = false;

      this.DOM.image_positions = [];
      this.DOM.swiperGallery = null;
      this.DOM.swiperContainer = this.DOM.el.querySelectorAll(
        '.bra-gallery__swiperWrapper',
      )[0];

      this.init_boundingSetup();
      this.init_swiper();
      this.init_interact();

      window.addEventListener('resize', () => this.init_boundingSetup());
    }
    init_boundingSetup() {
      Element.prototype.remove = function() {
        this.parentElement.removeChild(this);
      };
      NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
        for (var i = this.length - 1; i >= 0; i--) {
          if (this[i] && this[i].parentElement) {
            this[i].parentElement.removeChild(this[i]);
          }
        }
      };

      const removeElements = elms =>
        Array.from(elms).forEach(el => el.remove());
      removeElements(
        this.DOM.el.querySelectorAll('.bra-image__wrapper.cloned'),
      );

      this.DOM.image_positions = [];

      Array.from(this.DOM.braGalleries).forEach(gallery => {
        const images = gallery.querySelectorAll(
          '.bra-image__wrapper:not(.cloned)',
        );
        const images_length = images.length;
        const images_leftLength = gallery.querySelectorAll(
          '.bra-gallery__col--left .bra-image__wrapper:not(.cloned)',
        ).length;

        gallery.classList.add('bra-gallery--' + 'grid-' + images_length);

        let count = 0;

        Array.from(images).forEach(image => {
          let obj_index = [].indexOf.call(image.parentElement.children, image);
          let position = {
            left: image.offsetLeft,
            top: image.offsetTop,
            w: image.offsetWidth,
            h: image.offsetHeight,
          };

          let clone = image.cloneNode(true);
          clone.classList.add('cloned');
          clone.setAttribute('data-count', count);

          let parent = image.parentElement.className;
          if (parent == 'bra-gallery__col--right') {
            obj_index =
              [].indexOf.call(image.parentElement.children, image) +
              images_leftLength;
          } else {
            obj_index = [].indexOf.call(image.parentElement.children, image);
          }

          this.DOM.image_positions.push(position);

          let parentConatiner = gallery.querySelectorAll('.' + parent)[0];

          clone.style.position = 'absolute';
          clone.style.top = this.DOM.image_positions[obj_index]['top'] + 'px';
          clone.style.left = this.DOM.image_positions[obj_index]['left'] + 'px';
          clone.style.width = this.DOM.image_positions[obj_index]['w'] + 'px';
          clone.style.height = this.DOM.image_positions[obj_index]['h'] + 'px';

          parentConatiner.appendChild(clone);

          count++;
        });

        gallery.classList.add('ready');
      });
    }
    init_interact() {
      let _this = this;
      let gallery = this.DOM.el.querySelectorAll('.bra-gallery__wrapper')[0];

      //this.DOM.braGalleries.forEach(gallery => {
      const images = gallery.querySelectorAll('.bra-image__wrapper');
      const images_length = images.length;
      const images_leftLength = gallery.querySelectorAll(
        '.bra-gallery__col--left .bra-image__wrapper:not(.cloned)',
      ).length;

      const handleMouseEnter_gallery = e => {
        let obj = e.currentTarget;
        _this.controlGalleryOveraly(gallery, false);
      };
      const handleMouseLeave_gallery = e => {
        let obj = e.currentTarget;
        _this.controlGalleryOveraly(gallery, true);
      };

      const handleMouseEnter = e => {
        let obj = e.currentTarget;
        let parent = obj.parentElement.className;
        let obj_index = 0;
        let realIndex = [].indexOf.call(obj.parentElement.children, obj);
        const imageOverlay = obj.querySelectorAll('.bra-image__overlay')[0];

        if (parent == 'bra-gallery__col--right') {
          obj_index = realIndex + images_leftLength;
        } else {
          obj_index = realIndex;
        }

        _this.controlImageOveraly(imageOverlay, false);
      };

      const handleMouseLeave = e => {
        let obj = e.currentTarget;
        const imageOverlay = obj.querySelectorAll('.bra-image__overlay')[0];

        _this.controlImageOveraly(imageOverlay, true);
      };

      const handleClick = e => {
        let obj = e.currentTarget;
        let parent = obj.parentElement.className;
        let obj_index = 0;
        let realIndex = [].indexOf.call(obj.parentElement.children, obj);

        if (parent == 'bra-gallery__col--right') {
          obj_index = realIndex + images_leftLength;
        } else {
          obj_index = realIndex;
        }
        _this.zoomImage(
          obj_index,
          obj.parentElement.querySelectorAll('.cloned')[realIndex],
          false,
          globalOpenCloseDuration,
        );
        _this.DOM.swiperGallery.slideTo(obj_index, 1);
      };

      gallery.addEventListener('mouseenter', handleMouseEnter_gallery);
      gallery.addEventListener('mouseleave', handleMouseLeave_gallery);

      Array.from(images).forEach(image => {
        image.addEventListener('mouseenter', handleMouseEnter);
        image.addEventListener('mouseleave', handleMouseLeave);
        image.addEventListener('click', handleClick);
      });
      //});
    }
    async init_swiper() {
      const _this = this;

      if (this.DOM.image_positions.length == 2) {
        this.DOM.swiperGallery = await initCarousel(
          this.DOM.swiperContainer,
          {
            slidesPerViewXl: 1,
            slidesPerViewL: 'auto',
            slidesPerViewM: 'auto',
            slidesPerViewXs: 'auto',

            spaceBetweenXl: 60,
            spaceBetweenL: 5,
            spaceBetweenM: 5,
            spaceBetweenXs: 5,

            slidesPerGroupXl: 1,
            slidesPerGroupL: 1,
            slidesPerGroupM: 1,
            slidesPerGroupXs: 1,

            hideScrollbar: false,
          },
          {
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            watchOverflow: true,
            preloadImages: false,
            on: {
              transitionEnd: function() {
                if (_this.DOM.swiperGalleryActive) {
                  let allSliderCloned = _this.DOM.el.querySelectorAll(
                    '.bra-image__wrapper.cloned',
                  );

                  Array.prototype.forEach.call(allSliderCloned, function(
                    el,
                    i,
                  ) {
                    let thisId = el.getAttribute('data-count');
                    if (_this.DOM.swiperGallery.activeIndex == thisId) {
                      _this.zoomImage(
                        _this.DOM.swiperGallery.activeIndex,
                        _this.DOM.el.querySelectorAll(
                          '.bra-image__wrapper.cloned[data-count="' +
                            _this.DOM.swiperGallery.activeIndex +
                            '"]',
                        )[0],
                        false,
                        0.1,
                      );
                    } else {
                      _this.zoomImage(
                        thisId,
                        _this.DOM.el.querySelectorAll(
                          '.bra-image__wrapper.cloned[data-count="' +
                            thisId +
                            '"]',
                        )[0],
                        true,
                        0.1,
                      );
                    }
                  });
                }
              },
            },
          },
        );
      } else {
        this.DOM.swiperGallery = await initCarousel(
          this.DOM.swiperContainer,
          {
            slidesPerViewXl: 1,
            slidesPerViewL: 'auto',
            slidesPerViewM: 'auto',
            slidesPerViewXs: 'auto',

            spaceBetweenXl: 60,
            spaceBetweenL: 5,
            spaceBetweenM: 5,
            spaceBetweenXs: 5,

            slidesPerGroupXl: 1,
            slidesPerGroupL: 1,
            slidesPerGroupM: 1,
            slidesPerGroupXs: 1,

            slidesOffsetBeforeM: 30,
            slidesOffsetAfterM: 30,
            slidesOffsetBeforeXs: 20,
            slidesOffsetAfterXs: 20,
            hideScrollbar: false,
          },
          {
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            watchOverflow: true,
            preloadImages: false,
            on: {
              transitionEnd: function() {
                if (_this.DOM.swiperGalleryActive) {
                  let allSliderCloned = _this.DOM.el.querySelectorAll(
                    '.bra-image__wrapper.cloned',
                  );

                  Array.prototype.forEach.call(allSliderCloned, function(
                    el,
                    i,
                  ) {
                    let thisId = el.getAttribute('data-count');
                    if (_this.DOM.swiperGallery.activeIndex == thisId) {
                      _this.zoomImage(
                        _this.DOM.swiperGallery.activeIndex,
                        _this.DOM.el.querySelectorAll(
                          '.bra-image__wrapper.cloned[data-count="' +
                            _this.DOM.swiperGallery.activeIndex +
                            '"]',
                        )[0],
                        false,
                        0.1,
                      );
                    } else {
                      _this.zoomImage(
                        thisId,
                        _this.DOM.el.querySelectorAll(
                          '.bra-image__wrapper.cloned[data-count="' +
                            thisId +
                            '"]',
                        )[0],
                        true,
                        0.1,
                      );
                    }
                  });
                }
              },
            },
          },
        );
      }

      const handleClick_close = e => {
        _this.DOM.swiperContainer.classList.remove('visible');

        _this.zoomImage(
          _this.DOM.swiperGallery.activeIndex,
          _this.DOM.el.querySelectorAll(
            '.bra-image__wrapper.cloned[data-count="' +
              _this.DOM.swiperGallery.activeIndex +
              '"]',
          )[0],
          true,
          globalOpenCloseDuration,
        );

        let closingEl = _this.DOM.el.querySelectorAll(
          '.bra-image__wrapper.cloned[data-count="' +
            _this.DOM.swiperGallery.activeIndex +
            '"]',
        )[0];
        closingEl.classList.add('closing');
        _this.DOM.swiperGalleryActive = false;
      };

      let closeButtons = _this.DOM.el.querySelectorAll(
        '.bra-gallery__swiperWrapper .swiper-button-close',
      );

      for (const closeButton of closeButtons) {
        closeButton.addEventListener('click', handleClick_close);
      }
    }

    controlImageOveraly(target, reverse, duration) {
      const animation_target = target;
      const animation_reversed = reverse || false;
      const animation_duration = duration || 0.6;

      const zoomButton = animation_target.parentNode.querySelectorAll('button');
      const image = animation_target.parentNode.querySelectorAll('.bra-image');

      if (animation_reversed == true) {
        let tl__controlImageOveraly = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {},
        });

        tl__controlImageOveraly.call(() => {
          toggleClasses(animation_target, 'hover', false);
        });

        tl__controlImageOveraly.set(animation_target, {
          zIndex: 10,
        });

        tl__controlImageOveraly.to(
          animation_target,
          animation_duration,
          {
            opacity: 1,
          },
          0,
        );
        tl__controlImageOveraly.to(
          image,
          animation_duration,
          {
            scale: 1,
          },
          0,
        );
        tl__controlImageOveraly.to(
          zoomButton,
          animation_duration,
          {
            opacity: 0,
          },
          0,
        );

        tl__controlImageOveraly.play();
      } else {
        let tl__controlImageOveraly = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {},
        });

        tl__controlImageOveraly.call(() => {
          toggleClasses(animation_target, 'hover');
        });

        tl__controlImageOveraly.set(animation_target, {
          zIndex: 10,
        });

        tl__controlImageOveraly.to(
          animation_target,
          animation_duration,
          {
            opacity: 0,
          },
          0,
        );
        tl__controlImageOveraly.to(
          image,
          animation_duration,
          {
            scale: 1.05,
          },
          0,
        );
        tl__controlImageOveraly.to(
          zoomButton,
          animation_duration,
          {
            opacity: 1,
          },
          0,
        );

        tl__controlImageOveraly.play();
      }
    }
    controlGalleryOveraly(container, reverse) {
      const animation_container = container;
      const animation_target = container.querySelectorAll(
        '.bra-image__wrapper .bra-image__overlay',
      );
      const animation_reversed = reverse || false;
      const animation_duration = 0.6;
      const zoomButton = container.querySelectorAll(
        '.bra-image__wrapper button',
      );

      if (animation_reversed == true) {
        let tl__controlGalleryOveraly = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {},
        });

        tl__controlGalleryOveraly.call(() => {
          toggleClasses(animation_target, 'hover', false); // -
        });

        tl__controlGalleryOveraly.to(
          animation_target,
          animation_duration,
          {
            opacity: 0,
            force3D: true,
          },
          0,
        );
        tl__controlGalleryOveraly.to(
          zoomButton,
          animation_duration,
          {
            opacity: 0,
            force3D: true,
          },
          0,
        );

        tl__controlGalleryOveraly.play();
      } else {
        let tl__controlGalleryOveraly = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {},
        });

        tl__controlGalleryOveraly.call(() => {
          toggleClasses(container, 'hover');
        });

        tl__controlGalleryOveraly.to(
          animation_target,
          animation_duration,
          {
            opacity: 1,
            force3D: true,
          },
          0,
        );
        tl__controlGalleryOveraly.to(
          zoomButton,
          animation_duration,
          {
            opacity: 0,
            force3D: true,
          },
          0,
        );

        tl__controlGalleryOveraly.play();
      }
    }
    zoomImage(index, target, reverse, duration) {
      const _this = this;
      const reversedTL = reverse;

      const targetOverlay = target.querySelectorAll('.bra-image__overlay');
      const targetButton = target.querySelectorAll('button');

      if (reversedTL == true) {
        let tl__zoomImage = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {
            target.classList.remove('active');
            target.classList.remove('animating');

            let allClonedWrapper = document.querySelectorAll(
              '.bra-image__wrapper.cloned',
            );
            Array.prototype.forEach.call(allClonedWrapper, function(el, i) {
              el.classList.remove('closing');
            });
          },
        });

        tl__zoomImage.call(() => {
          toggleClasses(target, 'animating');
        });

        tl__zoomImage.set(target, {
          zIndex: 10,
        });
        tl__zoomImage.set(targetButton, {
          rotation: 45,
          opacity: 1,
        });

        tl__zoomImage.to(
          targetButton,
          duration,
          {
            rotation: 0,
            opacity: 0,
            transformOrigin: '50% 50%',
          },
          0,
        );

        tl__zoomImage.to(
          target,
          duration,
          {
            top: _this.DOM.image_positions[index]['top'],
            left: _this.DOM.image_positions[index]['left'],
            width: _this.DOM.image_positions[index]['w'],
            height: _this.DOM.image_positions[index]['h'],
            transformOrigin: '50% 50%',
          },
          0,
        );

        tl__zoomImage.play();
      } else {
        let tl__zoomImage = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power0.easeNone,
          onComplete: function() {
            target.classList.add('active');
            target.classList.remove('animating');

            _this.DOM.swiperGalleryActive = true;

            //let swiperWrapper = document.querySelectorAll('.bra-gallery__swiperWrapper')[0];
            _this.DOM.swiperContainer.classList.add('visible');
          },
        });

        tl__zoomImage.call(() => {
          toggleClasses(target, 'animating');
        });

        tl__zoomImage.set(target, {
          zIndex: 10,
          left: _this.DOM.image_positions[index]['left'],
          top: _this.DOM.image_positions[index]['top'],
        });
        tl__zoomImage.set(targetButton, {
          opacity: 1,
        });

        tl__zoomImage.to(
          target,
          duration,
          {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            transformOrigin: '50% 50%',
          },
          0,
        );

        tl__zoomImage.to(
          targetOverlay,
          duration,
          {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            transformOrigin: '50% 50%',
          },
          0,
        );

        tl__zoomImage.to(
          targetButton,
          duration,
          {
            rotation: 45,
            transformOrigin: '50% 50%',
          },
          0,
        );

        tl__zoomImage.play();
      }
    }
  }

  // New create carousel function and smartedit update event callback
  const createCarousel = async () => {
    const entries = SmarteditController.getEditorialEntries('.bra-gallery');
    if (_isEmpty(entries)) return;
    ({ TimelineLite, Power0, gsap, CSSPlugin } = await LazyGsap());
    gsap.registerPlugin(CSSPlugin);
    Array.from(entries).forEach(item => new Gallery(item));
  };
  SmarteditController.addOnReprocessPageListener(createCarousel);
  createCarousel();
};
