/* eslint-disable no-console */
import CONST from '@/utils/Constants/General';
import LazyGsap from '@/utils/Controllers/LazyGsapController';

let gsap;
const OPEN_CLASS = '--open';
const TRANSPARENT_CLASS = '--transparent';
const TOP_CLASS = '--top';
const STICKY_MENU_PDP_ID = 'js-bra-sticky-navigation';
const STRIPE_HIDDEN_CLASS = '--stripe-hidden';

let lastScrollTop = window.scrollY;
let animatingDown = false;
let animatingUp = false;
let stickyMenuPdpEl = null;
const offsetSnap = 10;

const hiddenStripeCheck = (headerEl, shouldHide) => {
  headerEl.classList.toggle(STRIPE_HIDDEN_CLASS, shouldHide);
};

const stickyMenuPdpCheck = goingDown => {
  if (stickyMenuPdpEl) {
    stickyMenuPdpEl.classList.toggle(TOP_CLASS, goingDown);
  }
};

const resetAnimation = headerEl => {
  const isTransparent = headerEl.classList.contains(TRANSPARENT_CLASS);
  gsap.to(headerEl, {
    backgroundColor: isTransparent ? 'transparent' : CONST.COLOR.BLACK,
    top: 0,
    duration: 0.3,
  });
};

const downAnimation = headerEl => {
  animatingDown = true;
  animatingUp = false;
  gsap.to(headerEl, {
    top: `-${headerEl.getBoundingClientRect().height}px`,
    duration: 0.5,
  });
};

const upAnimation = headerEl => {
  animatingDown = false;
  animatingUp = true;
  gsap.set(headerEl, { backgroundColor: CONST.COLOR.BLACK });
  gsap.to(headerEl, { top: 0, duration: 0.5 });
};

const initOnScrollBehaviour = async el => {
  if (!el) return;
  try {
    ({ gsap } = await LazyGsap());
    const scrollHandler = () => {
      const headerHeight = el.getBoundingClientRect().height;
      if (window.scrollY <= headerHeight) {
        resetAnimation(el);
        hiddenStripeCheck(el, false);
      }

      const goingDown = window.scrollY > lastScrollTop;
      if (!animatingDown && goingDown && window.scrollY > headerHeight) {
        if (!el.classList.contains(OPEN_CLASS)) {
          downAnimation(el);
        }
        stickyMenuPdpCheck(true); // check behaviour for sticky menu in pdp
        hiddenStripeCheck(el, true);
      } else if (!animatingUp && window.scrollY < lastScrollTop - offsetSnap) {
        if (!el.classList.contains(OPEN_CLASS)) {
          upAnimation(el);
        }
        stickyMenuPdpCheck(false); // check behaviour for sticky menu in pdp
      }

      // update with last values
      lastScrollTop = window.scrollY <= 0 ? 0 : window.scrollY;
    };

    // before everything check if we are not at the top of the page, if not, we remove the header
    if (lastScrollTop > el.getBoundingClientRect().height) {
      downAnimation(el);
    }

    // only for pdp, save the sticky menu
    stickyMenuPdpEl = document.getElementById(STICKY_MENU_PDP_ID);

    window.addEventListener('scroll', scrollHandler);
  } catch (err) {
    console.error('initOnScrollBehaviour::Something went wrong!');
    console.error('initOnScrollBehaviour::', err);
  }
};

export default initOnScrollBehaviour;
