import SmarteditController from '@/utils/Controllers/SmarteditController';

const IFRAME_ID = 'js-editorial-iframe-wrapper';

const initController = () => {
  const iframe = SmarteditController.getEditorialEntries(`#${IFRAME_ID}`);

  if (!iframe || iframe.length === 0) return;

  window.addEventListener(
    'message',
    event => {
      if (event.data.usage === 'iframeHeight') {
        iframe[0].style.height = `${event.data.height}px`;
      }
    },
    false,
  );
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initController);
  initController();
};
