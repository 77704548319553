import BraunHeaderAndSmartEditController from '@/braun/Controllers/BraunHeaderAndSmartEditController';
import HeaderController from '@/braun/Controllers/Header';
import VideoBannerComponentController from '@/braun/Controllers/VideoBannerComponentController';
import '@/style/braun/Main.scss';

// components controller
import AttachmentExplanationInitiator from '@/braun/Controllers/AttachmentExplanation/AttachmentExplanationInitiator';
import BraunBreadcrumbsController from '@/braun/Controllers/BraunBreadcrumbsController';
import StripeLinkSliderController from '@/utils/Controllers/StripeLinkSliderController';
import BraunRecipeTeaserSliderController from '@/braun/Controllers/BraunRecipeTeaserSliderController';
import BraunSkuSliderController from '@/braun/Controllers/BraunSkuSliderController';
import BraunWishlistController from '@/braun/Controllers/BraunWishlistController';
import CategoryShelfController from '@/braun/Controllers/CategoryShelf/CategoryShelfController';
import GalleryComponentController from '@/braun/Controllers/GalleryComponentController';
import initImageSliderController from '@/braun/Controllers/ImageSliderController';
import InteractiveBlockInitiator from '@/braun/Controllers/InteractiveBlock/InteractiveBlockInitiator';
import MovingLettersController from '@/braun/Controllers/MovingLettersController';
import initMultiAccordionController from '@/braun/Controllers/MultiAccordionController';
import MultiquickStageController from '@/braun/Controllers/MultiquickStageController';
import initParallaxController from '@/braun/Controllers/ParallaxController';
import SecondaryContentSliderInitiator from '@/braun/Controllers/SecondaryContentSlider/SecondaryContentSliderInitiator';
import initStageComponentController from '@/braun/Controllers/StageComponentController';
import initStageCountDownComponentController from '@/braun/Controllers/StageCountDownComponentController';
import initStoriesComponentnController from '@/braun/Controllers/StoriesComponentController';
import initStageVideoComponentController from '@/braun/Controllers/VideoComponentController';
import AllContentSliderController from '@/utils/Controllers/AllContentSliderController';
import CategorySliderController from '@/utils/Controllers/CategorySliderController';
import BraunIframeComponentController from '@/braun/Controllers/BraunIframeComponentController';
import BRAVideoFullController from '@/braun/Controllers/BRAVideoFull/BRAVideoFullInitiator';
import BRAVideoSliderController from '@/braun/Controllers/BRAVideoSlider/BRAVideoSliderInitiator';

document.addEventListener('DOMContentLoaded', () => {
  BraunHeaderAndSmartEditController();
  HeaderController();
});

window.addEventListener('load', () => {
  initParallaxController();
  initStageComponentController();
  initStageCountDownComponentController();
  initStageVideoComponentController();
  InteractiveBlockInitiator();
  initImageSliderController();
  GalleryComponentController();
  BraunSkuSliderController();
  CategorySliderController();
  StripeLinkSliderController();
  BraunRecipeTeaserSliderController();
  AllContentSliderController();
  initMultiAccordionController();
  initStoriesComponentnController();
  VideoBannerComponentController();
  BRAVideoSliderController();
  BRAVideoFullController();

  BraunWishlistController();
  BraunBreadcrumbsController();
  MovingLettersController();
  AttachmentExplanationInitiator();
  MultiquickStageController();
  CategoryShelfController();
  SecondaryContentSliderInitiator();

  BraunIframeComponentController();
});
