import { initCarousel } from '@/braun/Controllers/BraunCarouselController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const initImageSlider = () => {
    const entries = SmarteditController.getEditorialEntries('.image-slider');
    Array.from(entries).forEach(slider => {
      initCarousel(slider, {
        slidesPerViewXl: 'auto',
        slidesPerViewL: 'auto',
        slidesPerViewM: 'auto',
        slidesPerViewXs: 'auto',
        paginationType: 'bullets',
        autoplay: slider.dataset.autoplay,
        delay: slider.dataset.delay,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        preloadImages: false,
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(initImageSlider);
  initImageSlider();
};
