import '@/style/braun/Main.scss';
import { isTrue } from '@/utils/Common/Booleans';
import BraunHamburgerController from '@/braun/Controllers/BraunHamburgerController';
import BraunHeaderController from '@/braun/Controllers/BraunHeaderController';
import BraunNavigationContainerController from '@/braun/Controllers/BraunNavigationContainerController';
import BraunUserController from '@/braun/Controllers/BraunUserController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

const initHeader = () => {
  BraunHeaderController();

  if (!isTrue(DLG.config.liteHeaderFooter)) {
    BraunHamburgerController();
    BraunUserController();
    BraunNavigationContainerController();
  }
};

export default () => {
  const headerContainer = document.getElementsByClassName('bra-header')[0];
  if (!headerContainer) {
    document.body.classList.add('headerReady');
    return;
  }
  SmarteditController.addOnReprocessPageListener(initHeader);
  initHeader();
};
