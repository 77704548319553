/* eslint-disable */
import { initCarousel } from '@/braun/Controllers/BraunCarouselController';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

let CSSPlugin;
let Power3;
let TimelineLite;
let gsap;

const initCard_Recipe = entries => {
  class Item {
    constructor(el) {
      const _this = this;
      this.DOM = { el };
      this.DOM.content = this.DOM.el.querySelector('.bra-card__content');
      this.DOM.visual = this.DOM.el.querySelector('.bra-card__visual');
      this.DOM.actions = this.DOM.el.querySelector('.bra-card__actions');
      this.DOM.btn = this.DOM.actions.querySelector('.bra-btn');

      window.addEventListener('resize', () => resize());

      const resize = () => {
        // console.log(document.body.clientWidth);

        const tl__card_enter = new TimelineLite({
          paused: true,
          reversed: false,
          ease: Power3.easeInOut,
          onComplete() {
            // console.log('complete')
          },
        });

        const offset = 70;

        tl__card_enter.fromTo(
          this.DOM.content,
          0.3,
          {
            y: '0',
          },
          {
            y: `-${offset}px`,
          },
          0,
        );

        tl__card_enter.fromTo(
          this.DOM.visual,
          0.8,
          {
            scale: 1,
          },
          {
            scale: 1.05,
          },
          0,
        );

        tl__card_enter.fromTo(
          this.DOM.actions,
          0.5,
          {
            opacity: 0,
            y: `${offset}px`,
          },
          {
            opacity: 1,
            y: '0',
          },
          0,
        );

        tl__card_enter.fromTo(
          this.DOM.btn,
          0.5,
          {
            opacity: 0,
            force3D: true,
          },
          {
            opacity: 1,
            force3D: true,
          },
          0.2,
        );

        if (document.body.clientWidth >= 993) {
          // console.log('true');
        } else {
          this.DOM.content.removeAttribute('style');
          this.DOM.visual.removeAttribute('style');
          this.DOM.actions.removeAttribute('style');
          this.DOM.btn.removeAttribute('style');
        }

        this.DOM.el.addEventListener('mouseenter', () => onMouse('enter'));
        this.DOM.el.addEventListener('mouseleave', () => onMouse('leave'));

        function onMouse(status) {
          if (document.body.clientWidth >= 768) {
            _this.DOM.el.classList.add('active');
            if (status == 'enter') {
              tl__card_enter.timeScale(1).play();
            } else if (status == 'leave') {
              tl__card_enter
                .timeScale(1)
                .play()
                .reversed(true);
            }
          } else {
            _this.DOM.content.removeAttribute('style');
            _this.DOM.visual.removeAttribute('style');
            _this.DOM.actions.removeAttribute('style');
            _this.DOM.btn.removeAttribute('style');
          }
        }
      };

      resize();
    }
  }

  Array.from(entries).forEach(item => new Item(item));
};

const initRecipeSlider = entries => {
  Array.from(entries).forEach(recipeGallerySlider => {
    initCarousel(
      recipeGallerySlider,
      {
        slidesPerViewXl: 'auto',
        slidesPerViewL: 'auto',
        slidesPerViewM: 'auto',
        slidesPerViewXs: 'auto',

        slidesPerGroupXl: 3,
        slidesPerGroupL: 3,
        slidesPerGroupM: 2,
        slidesPerGroupXs: 1,

        spaceBetweenXl: 20,
        spaceBetweenL: 20,
        spaceBetweenM: 20,
        spaceBetweenXs: 10,

        slidesOffsetBeforeXl: 0,
        slidesOffsetBeforeL: 0,
        slidesOffsetBeforeM: 30,
        slidesOffsetBeforeXs: 20,

        slidesOffsetAfterXl: 0,
        slidesOffsetAfterL: 0,
        slidesOffsetAfterM: 30,
        slidesOffsetAfterXs: 20,

        hideScrollbar: false,
      },
      {
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        preloadImages: false,
        on: {
          transitionEnd() {
            const thisSwiper = this.$wrapperEl[0];
            const allSlides = thisSwiper.getElementsByClassName(
              'bra-card--recipe',
            );

            for (let i = 0; i < allSlides.length; i++) {
              allSlides[i].classList.remove('active');
            }

            const activeSlide = thisSwiper.getElementsByClassName(
              'swiper-slide-active',
            )[0];
            const activeSlide__card = activeSlide.getElementsByClassName(
              'bra-card--recipe',
            )[0];

            activeSlide__card.classList.add('active');
          },
        },
      },
    );
  });
};

const initAll = async () => {
  const cardRecipes = SmarteditController.getEditorialEntries(
    '.bra-card--recipe',
  );
  const recipeGalleries = SmarteditController.getEditorialEntries(
    '.recipe-gallery',
  );

  if (_isEmpty(cardRecipes) && _isEmpty(recipeGalleries)) return;

  ({ TimelineLite, Power3, gsap, CSSPlugin } = await LazyGsap());
  gsap.registerPlugin(CSSPlugin);

  if (!_isEmpty(cardRecipes)) initCard_Recipe(cardRecipes);
  if (!_isEmpty(recipeGalleries)) initRecipeSlider(recipeGalleries);
};

export default () => {
  initAll();
  SmarteditController.addOnReprocessPageListener(initAll);
};
