import LazyGsap, {
  LazyGsapScrollTrigger,
} from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

let gsap;

const MultiQuick = wrapper => {
  const TEXTS = wrapper.querySelector('.js-texts');
  const KICKER = wrapper.querySelector('.js-kicker');
  const TITLE = wrapper.querySelector('.js-title');
  const BLOB = wrapper.querySelector('.js-blob');
  const ENGINE = wrapper.querySelector('.js-engine');
  const ACCESSORIES = wrapper.querySelectorAll('.js-accessories');

  const mmDesktop = window.matchMedia('(min-width: 600px)').matches;

  const config = {
    onLoad: {
      wrapper: {
        to: { opacity: 1 },
      },
      engine: {
        to: { y: 0, opacity: 1, duration: 1 },
      },
      title: {
        from: { y: -200, opacity: 0 },
        to: { y: 0, opacity: 1, duration: 0.7 },
      },
      kicker: {
        to: { opacity: 1, duration: 0.5 },
      },
      blob: {
        from: { scaleY: 0.7, opacity: 0 },
        to: { scaleY: 1, opacity: 1 },
      },
    },
    onScroll: {
      engine: {
        from: {},
        to: {
          scale: mmDesktop ? 0.45 : 0.5,
          y: mmDesktop ? -150 : -90,
        },
      },
      blob: {
        to: {
          opacity: 0,
          scaleY: 0.5,
          duration: 0.2,
          transformOrigin: 'center',
        },
      },
      accessories: {
        from: { scale: mmDesktop ? 3 : 2, y: '120%', opacity: 1 },
        to: {
          scale: mmDesktop ? 0.8 : 0.5,
          yOffset: mmDesktop ? 550 : 475,
          opacity: 1,
          duration: 1,
        },
      },
    },
  };

  const tl = gsap.timeline();
  tl.to(wrapper, config.onLoad.wrapper.to)
    .to(ENGINE, config.onLoad.engine.to)
    .fromTo(TITLE, config.onLoad.title.from, config.onLoad.title.to, '-=.2')
    .to(KICKER, config.onLoad.kicker.to)
    .fromTo(BLOB, config.onLoad.blob.from, config.onLoad.blob.to, '-=.2')
    .then(() => {
      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: ENGINE,
          scrub: 0,
          start: 50,
          end: () => `+=${ENGINE.offsetHeight / 8}`,
        },
      });
      tl2
        .to(ENGINE, config.onScroll.engine.to, '<')
        .to(BLOB, config.onScroll.blob.to, '-=.5')
        .fromTo(
          ACCESSORIES,
          config.onScroll.accessories.from,
          {
            y: `-${ENGINE.offsetHeight / 2 +
              config.onScroll.accessories.to.yOffset}px`,
            scale: config.onScroll.accessories.to.scale,
            opacity: config.onScroll.accessories.to.opacity,
            duration: config.onScroll.accessories.to.duration,
            onUpdate: () => {
              const offset = mmDesktop ? 300 : 150;
              const textsRect = TEXTS.getBoundingClientRect();
              const engineRect = ENGINE.getBoundingClientRect();
              const accessoriesRect = ACCESSORIES[0].getBoundingClientRect();
              const newHeight =
                textsRect.height +
                engineRect.height +
                accessoriesRect.height -
                offset;
              wrapper.style.height = `${newHeight}px`;
            },
          },
          '-=.5',
        );
    });
};

const init = async () => {
  const els = document.querySelectorAll('.js-bra-mqs');
  if (els.length < 1) return;
  ({ gsap } = await LazyGsap());
  const ScrollTrigger = await LazyGsapScrollTrigger();
  gsap.registerPlugin(ScrollTrigger);
  els.forEach(el => MultiQuick(el));
};

export default () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
