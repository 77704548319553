/* eslint-disable no-console */
import MenuAim from '@/utils/Common/MenuAim';
import CONST from '@/utils/Constants/General';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import LazySimplebar from '@/utils/Controllers/LazySimplebarController';
import _debounce from 'lodash/debounce';
import _isFunction from 'lodash/isFunction';
import { showCardInWrapper } from './NavigationMobileController';

const LINK_TAG = 'A';
const BP_L = 1024;
let gsap;

let isFirstSetup = true;
let isNavigationOpen = false;
let isNavigationClosing = false;
let currentOpenWrapperUid = null;
let currentOpenWrapperCardUid = null;

const MENU_ID = 'js-braun-header';
const MENU_CONTAINER_SELECTOR = '#js-braun-header-container';
const NAV_CARDS_WRAPPER_SELECTOR = '#js-braun-nav-desktop__card-wrapper';
const MENU_LINK_TRIGGER_SELECTOR = '.js-braun-header-menu-link';
const NAV_MENU_SELECTOR = '#js-braun-nav-desktop';
const NAV_MENU_CLOSE_CTA_SELECTOR = '#js-braun-nav-desktop__close-cta';
const MASK_SELECTOR = '#js-braun-nav-desktop__mask';
const DRAWER_SELECTOR = '#js-braun-nav-desktop__drawer';
const DRAWER_CONTENT_SELECTOR = '#js-braun-nav-desktop__drawer-content';
const CARDS_HIDDEN_WRAPPER_ID = 'js-braun-header-card-hidden-wrapper';
const SUBMENU_WRAPPER_SELECTOR = '.js-braun-nav-desktop__submenu-wrapper';
const SUBMENU_CONTENT_SELECTOR = '.js-braun-nav-desktop__submenu-content';
const SUBMENU2_UL_SELECTOR = '.js-braun-nav-desktop__submenu2-ul';
const SUBMENU2_CTA_SELECTOR = '.js-braun-nav-desktop__submenu2-cta';
const SUBMENU3_WRAPPER_SELECTOR = '.js-braun-nav-desktop__submenu3-wrapper';
const NAV_ACCOUNT_DRAWER_SELECTOR = '.js-braun-header-account-wrapper';

const OPEN_CLASS = '--open';

let headerEl = null;
let headerContainerEl = null;
let menuLinksTrigger = null;
let navMenuEl = null;
let maskEl = null;
let drawerEl = null;
let drawerContentEl = null;
let cardsHiddenWrapperEl = null;
let navMenuCloseCtaEl = null;
let submenuWrapperEls = null;
let submenuContentEls = null;
let submenu2UlEls = null;
let submenu2CtaEls = null;
let submenu3WrapperEls = null;
const simplebarElsMap = new Map();

let mainTl = null;

export const closeAccountDrawer = () => {
  const entries = headerEl.querySelectorAll(NAV_ACCOUNT_DRAWER_SELECTOR);
  Array.from(entries).forEach(entry => {
    entry.classList.remove('hover');
    const input = entry.querySelector('input');
    input.checked = false;
  });
};

const setCardInWrapper = (submenuWrapperUid, cardUid, parentCardUid) => {
  try {
    // getting the card wrapper where we need to put the proper card component
    const cardWrapperEl = headerEl.querySelector(
      `${NAV_CARDS_WRAPPER_SELECTOR}__${submenuWrapperUid}`,
    );

    if (!cardWrapperEl || !cardsHiddenWrapperEl) return;

    // we pick the right card id
    const currentCardUid = cardUid || parentCardUid;

    // show card (method shared with mobile)
    showCardInWrapper(cardWrapperEl, currentCardUid);
  } catch (err) {
    console.error('setCardInWrapper::Something went wrong!');
    console.error('setCardInWrapper::', err);
  }
};

const generateSimplebar = async (el, uid, autoHide = false) => {
  let simplebarEl = simplebarElsMap.get(uid);
  if (simplebarEl) {
    simplebarEl.getScrollElement().scrollTop = 0;
    simplebarEl.recalculate();
  } else {
    const SimplebarLib = await LazySimplebar();
    simplebarEl = new SimplebarLib(el, { autoHide });
    simplebarElsMap.set(uid, simplebarEl);
  }
};

const openDrawer = selectedSubmenuWrapper => {
  try {
    if (mainTl) mainTl.pause();
    mainTl = gsap.timeline();
    // close account drawer
    closeAccountDrawer();
    // set variables
    isNavigationOpen = true;
    const { uid, cardUid } = selectedSubmenuWrapper.dataset;
    currentOpenWrapperUid = uid;
    currentOpenWrapperCardUid = cardUid;
    headerEl.classList.add(OPEN_CLASS); // maybe instead of a class some gsap config?
    // set the card inside the card wrapper
    setCardInWrapper(currentOpenWrapperUid, currentOpenWrapperCardUid);

    mainTl
      .set(navMenuEl, { display: 'block' })
      .set(submenuWrapperEls, { display: 'none' })
      .set(selectedSubmenuWrapper, { display: 'block', opacity: 1 })
      .fromTo(maskEl, { opacity: 0 }, { opacity: 1, duration: 0.3 })
      .fromTo(drawerEl, { y: '-100%' }, { y: 0, duration: 0.3 }, '-=.2')
      .fromTo(
        drawerContentEl,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.5 },
      )
      .set(headerContainerEl, { backgroundColor: CONST.COLOR.WHITE });
    generateSimplebar(drawerContentEl, 'mainMenu');
  } catch (err) {
    console.error('openDrawer::Something went wrong!');
    console.error('openDrawer::', err);
  }
};

const closeDrawer = onCompleteCb => {
  try {
    if (isNavigationClosing) return;
    isNavigationClosing = true;
    if (mainTl) mainTl.pause();
    mainTl = gsap.timeline();
    // close account drawer
    closeAccountDrawer();
    // unset variables
    isNavigationOpen = false;
    currentOpenWrapperUid = null;
    currentOpenWrapperCardUid = null;

    mainTl
      .set(headerContainerEl, { backgroundColor: 'unset' })
      .fromTo(drawerContentEl, { opacity: 1 }, { opacity: 0, duration: 0.3 })
      .fromTo(drawerEl, { y: 0 }, { y: '-100%', duration: 0.3 }, '-=.2')
      .fromTo(maskEl, { opacity: 1 }, { opacity: 0, duration: 0.3 }, '-=.2')
      .set(navMenuEl, { display: 'none' })
      .then(() => {
        headerEl.classList.remove(OPEN_CLASS);
        // remove open class
        menuLinksTrigger.forEach(menuLink => {
          menuLink.classList.remove(OPEN_CLASS);
        });
        isNavigationClosing = false;
        // possible cb after animation ends
        if (_isFunction(onCompleteCb)) onCompleteCb();
      });
  } catch (err) {
    console.error('closeDrawer::Something went wrong!');
    console.error('closeDrawer::', err);
  }
};

const switchSubmenuWrapper = selectedSubmenuWrapper => {
  try {
    if (mainTl) mainTl.pause();
    mainTl = gsap.timeline();
    // set variables
    const { uid, cardUid } = selectedSubmenuWrapper.dataset;
    currentOpenWrapperUid = uid;
    currentOpenWrapperCardUid = cardUid;

    // set the card inside the card wrapper
    setCardInWrapper(currentOpenWrapperUid, currentOpenWrapperCardUid);
    // animate only the wrapper
    mainTl
      .set(drawerContentEl, { opacity: 1, y: 0 })
      .set(drawerEl, { y: 0 })
      .set(submenuWrapperEls, { display: 'none' })
      .set(selectedSubmenuWrapper, {
        display: 'block',
      })
      .fromTo(
        selectedSubmenuWrapper,
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.5 },
      )
      .set(headerContainerEl, { backgroundColor: CONST.COLOR.WHITE });
  } catch (err) {
    console.error('switchSubmenuWrapper::Something went wrong!');
    console.error('switchSubmenuWrapper::', err);
  }
};

const mainAnimation = wrapperUid => {
  try {
    // getting the submenu wrapper to show if present.
    const selectedSubmenuWrapper = submenuWrapperEls.find(
      submenu => submenu.dataset.uid === wrapperUid,
    );

    // CASE1: nothing is open and we are hovering a link WITHOUT a submenu
    // ACTION: do nothing, it's just a simple anchor tag with a link to another page
    if (!isNavigationOpen && !selectedSubmenuWrapper) {
      return;
    }

    // CASE2: drawer is open and we are hovering a link WITHOUT a submenu
    // ACTION: we need to close the drawer
    if (isNavigationOpen && !selectedSubmenuWrapper) {
      closeDrawer();
      return;
    }

    // CASE3: drawer is open and we are hovering the same link
    // ACTION: do nothing, no needs to start a new animation
    if (
      isNavigationOpen &&
      currentOpenWrapperUid === selectedSubmenuWrapper.dataset.uid
    ) {
      return;
    }

    // CASE4: nothing is open and we are hovering a link WITH a submenu
    // ACTION: we need to trigger the default animation
    if (!isNavigationOpen && selectedSubmenuWrapper) {
      openDrawer(selectedSubmenuWrapper);
      return;
    }

    // CASE5: drawer is open and we are hovering a different link WITH a submenu
    // ACTION: we need just to swap submenus wrapper, no need to start the main animation from start
    if (
      isNavigationOpen &&
      currentOpenWrapperUid !== selectedSubmenuWrapper.dataset.uid
    ) {
      switchSubmenuWrapper(selectedSubmenuWrapper);
      return;
    }

    // this should never happen
    console.error('Something went wrong, case not handled');
  } catch (err) {
    console.error('mainAnimation::Something went wrong!');
    console.error('mainAnimation::', err);
  }
};

const hideMenuLvl3 = () => {
  try {
    // reset card wrapper if necessary
    setCardInWrapper(currentOpenWrapperUid, currentOpenWrapperCardUid);

    submenu2CtaEls.forEach(cta => cta.classList.remove(OPEN_CLASS));

    gsap.set(submenu3WrapperEls, { display: 'none', scrollTop: 0 });
  } catch (err) {
    console.error('hideMenuLvl3::Something went wrong!');
    console.error('hideMenuLvl3::', err);
  }
};

const showMenuLvl3 = e => {
  try {
    const target = e?.currentTarget || e; // if it's not an event its a cta from menuAim activate method in setupMenuAim
    const { uid, cardUid, parentCardUid } = target.dataset;
    // if the menu its already open, no need to reopen it
    if (target.classList.contains(OPEN_CLASS)) return;

    hideMenuLvl3();

    // update if necessary the card component
    setCardInWrapper(currentOpenWrapperUid, cardUid, parentCardUid);

    // if we are hovering an anchor tag, no needs to show submenu, there is no submenu at all
    if (target.tagName === LINK_TAG) return;

    target.classList.add(OPEN_CLASS);
    const selectedSubMenu3El = submenu3WrapperEls.find(
      ul => ul.dataset.parentUid === target.dataset.uid,
    );
    gsap.set(selectedSubMenu3El, { opacity: 0, display: 'flex' });
    gsap
      .fromTo(
        selectedSubMenu3El,
        { x: -40 },
        { opacity: 1, x: 0, duration: 0.2 },
      )
      .then(() => {
        generateSimplebar(selectedSubMenu3El, uid);
      });
  } catch (err) {
    console.error('showMenuLvl3::Something went wrong!');
    console.error('showMenuLvl3::', err);
  }
};

// this is the method that use MenuAim in order to avoid flickering between nested menus
const setupMenuAim = el => {
  try {
    const activate = e => {
      showMenuLvl3(e.querySelector(SUBMENU2_CTA_SELECTOR));
    };
    const deactivate = () => true;
    const exitMenu = () => true;
    MenuAim(el, { activate, deactivate, exitMenu });
  } catch (err) {
    console.error('setupMenuAim::Something went wrong!');
    console.error('setupMenuAim::', err);
  }
};

const menuResizeHandler = () => {
  const isDesktopMQ = window.matchMedia(`(min-width: ${BP_L}px)`);
  if (isNavigationOpen && !isDesktopMQ.matches) {
    // hide everything
    closeDrawer(hideMenuLvl3);
  }
};

const setupElements = () => {
  try {
    headerContainerEl = headerEl.querySelector(MENU_CONTAINER_SELECTOR);
    navMenuEl = headerEl.querySelector(NAV_MENU_SELECTOR);
    maskEl = headerEl.querySelector(MASK_SELECTOR);
    drawerEl = headerEl.querySelector(DRAWER_SELECTOR);
    drawerContentEl = headerEl.querySelector(DRAWER_CONTENT_SELECTOR);
    cardsHiddenWrapperEl = document.getElementById(CARDS_HIDDEN_WRAPPER_ID);
    navMenuCloseCtaEl = headerEl.querySelector(NAV_MENU_CLOSE_CTA_SELECTOR);
    submenuWrapperEls = Array.from(
      headerEl.querySelectorAll(SUBMENU_WRAPPER_SELECTOR),
    );
    submenuContentEls = Array.from(
      headerEl.querySelectorAll(SUBMENU_CONTENT_SELECTOR),
    );
    submenu2UlEls = Array.from(headerEl.querySelectorAll(SUBMENU2_UL_SELECTOR));
    submenu2CtaEls = Array.from(
      headerEl.querySelectorAll(SUBMENU2_CTA_SELECTOR),
    );
    submenu3WrapperEls = Array.from(
      headerEl.querySelectorAll(SUBMENU3_WRAPPER_SELECTOR),
    );
  } catch (err) {
    console.error('setupElements::Something went wrong!');
    console.error('setupElements::', err);
  }
};

const setupEvents = () => {
  try {
    maskEl.addEventListener('mouseover', closeDrawer);
    navMenuCloseCtaEl.addEventListener('click', closeDrawer);
    submenuContentEls.forEach(el => {
      el.addEventListener('mouseleave', hideMenuLvl3);
    });
    submenu2UlEls.forEach(menu => {
      setupMenuAim(menu);
    });
    submenu2CtaEls.forEach(cta => {
      cta.addEventListener('click', showMenuLvl3);
    });
    window.addEventListener('resize', _debounce(menuResizeHandler, 200));
  } catch (err) {
    console.error('toggleMenu::Something went wrong!');
    console.error('toggleMenu::', err);
  }
};

const toggleMenu = el => {
  try {
    const { currentTarget } = el;
    const {
      dataset: { uid },
    } = currentTarget;

    // remove open class from others link at lvl0
    menuLinksTrigger.forEach(menuLink => {
      menuLink.classList.remove(OPEN_CLASS);
    });

    if (currentTarget.tagName !== LINK_TAG) {
      currentTarget.classList.add(OPEN_CLASS);
    }

    if (!isFirstSetup) {
      mainAnimation(uid);
    } else {
      isFirstSetup = false;
      // set-up initial elements
      setupElements();
      // execute main animation
      mainAnimation(uid);
      // set-up events
      setupEvents();
    }
  } catch (err) {
    console.error('toggleMenu::Something went wrong!');
    console.error('toggleMenu::', err);
  }
};

const initNavigation = async () => {
  try {
    menuLinksTrigger = Array.from(
      headerEl.querySelectorAll(MENU_LINK_TRIGGER_SELECTOR),
    );
    if (menuLinksTrigger.length === 0) return;

    ({ gsap } = await LazyGsap());
    menuLinksTrigger.forEach(menuLink => {
      menuLink.addEventListener('mouseenter', toggleMenu);
      menuLink.addEventListener('click', toggleMenu);
    });
  } catch (err) {
    console.error('initNavigation::Something went wrong!');
    console.error('initNavigation::', err);
  }
};

const NavigationDesktopController = () => {
  headerEl = document.getElementById(MENU_ID);
  if (!headerEl) return;

  initNavigation();
};

export default NavigationDesktopController;
