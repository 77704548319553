import i18n from '@/i18n';
import { goWishlist } from '@/utils/Common/Redirects';
import EVENTS from '@/utils/Constants/Events';
import CONST from '@/utils/Constants/General';
import PropTypes from 'prop-types';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

// import components
import ButtonIconBraun from '@/components/atoms/ButtonIconBraun';
import MiniWishlistDrawer from '@/components/molecules/MiniWishlistDrawer';
import SmarteditController from '@/utils/Controllers/SmarteditController';

const WISHLIST_COUNTER_ID = 'js-nav-mobile-wishlist-counter';

const MiniWishlistBraun = ({ glyph, modifiers }) => {
  const [counter, setCounter] = useState(null);
  const [drawerIsVisible, setDrawerIsVisible] = useState(false);
  const timerRef = useRef(null);

  const loadAddDrawer = () => {
    setDrawerIsVisible(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setDrawerIsVisible(false);
    }, 5000);
  };

  const updateCounterUI = count => {
    setCounter(count);
    const el = document.getElementById(WISHLIST_COUNTER_ID);
    if (el) {
      el.style.display = count >= 1 ? 'block' : 'none';
      el.innerHTML = count;
    }
  };

  useEffect(() => {
    DLG.EVE.on(EVENTS.BALOON.UPDATE.WISHLIST, updateCounterUI);
    DLG.EVE.on(EVENTS.WISHLIST.ENTRY.DRAWER.ADD, loadAddDrawer);
    DLG.EVE.emit(EVENTS.WISHLIST.GET, { type: CONST.WISHLIST.TOTAL });
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
  return (
    <ButtonIconBraun
      modifiers={modifiers.split(' ')}
      glyph={glyph}
      onClick={goWishlist}
      counter={counter}
      drawerIsVisible={drawerIsVisible}
      Drawer={MiniWishlistDrawer}
    />
  );
};

MiniWishlistBraun.propTypes = {
  glyph: PropTypes.string,
  modifiers: PropTypes.string,
};

MiniWishlistBraun.defaultProps = {
  glyph: null,
  modifiers: '',
};

const PortalComponent = () => {
  const el = document.getElementById('js-r-entry-braun-miniwishlist');
  if (el && !el.hasChildNodes()) {
    i18n.loadNamespaces(['wishlist']);
    return ReactDOM.createPortal(
      <I18nextProvider i18n={i18n}>
        <Suspense fallback={<div />}>
          <MiniWishlistBraun {...el.dataset} />
        </Suspense>
      </I18nextProvider>,
      el,
    );
  }
  return null;
};

export const renderWishlistIconPortal = () => {
  const entries = SmarteditController.getEditorialEntries(
    '#js-braun-header-wishlist-portal',
  );
  if (entries[0]) {
    ReactDOM.render(<PortalComponent />, entries[0]);
  }
};

export default PortalComponent;
