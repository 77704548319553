import SmarteditController from '@/utils/Controllers/SmarteditController';

const handleActiveMultiAccordion = () => {
  const multiAccordionItems = document.querySelectorAll(
    '.bra-multi-accordion__expander',
  );

  multiAccordionItems.forEach(multiAccordionItem => {
    if (multiAccordionItem.classList.contains('active')) {
      const accordionItemContent = multiAccordionItem.parentElement.querySelector(
        '.bra-multi-accordion__content',
      );
      const contentChildHeight = accordionItemContent.firstChild.offsetHeight;

      accordionItemContent.style.maxHeight = `${contentChildHeight}px`;
    }
  });
};

export default () => {
  const accordionArray = [
    '.bra-multi-accordion--faqs',
    '.bra-multi-accordion--specifications',
    '.bra-multi-accordion--terms-and-conditions',
    '.bra-multi-accordion--promo',
  ];
  const initMultiAccordionEvent = accordion => {
    handleActiveMultiAccordion();
    accordion.addEventListener('click', event => {
      const expander = event.target.classList.contains(
        'bra-multi-accordion__expander',
      )
        ? event.target
        : event.target.closest('.bra-multi-accordion__expander') || null;

      if (event.target && expander) {
        const accordionOpen = accordion.querySelector('.active');
        const eventIsActive = expander.classList.contains('active');
        const accordionContent = expander.parentElement.querySelector(
          '.bra-multi-accordion__content',
        );
        const accordionContentHeight =
          accordionContent.firstElementChild.offsetHeight;

        if (accordionOpen !== null) {
          accordionOpen.classList.remove('active');
          accordionOpen.setAttribute('aria-expanded', false);
          accordionOpen.parentElement
            .querySelector('.bra-multi-accordion__content')
            .removeAttribute('style');
        }

        if (!eventIsActive) {
          expander.classList.add('active');
          expander.setAttribute('aria-expanded', true);
          accordionContent.style.maxHeight = `${accordionContentHeight}px`;
        }
      }
    });
  };

  const initMultiAccordion = () => {
    Array.from(accordionArray).forEach(accordionClass => {
      const entries = SmarteditController.getEditorialEntries(accordionClass);
      Array.from(entries).forEach(accordion =>
        initMultiAccordionEvent(accordion),
      );
    });
  };
  SmarteditController.addOnReprocessPageListener(initMultiAccordion);
  initMultiAccordion();
};
