/* eslint-disable camelcase,no-unused-expressions,no-empty */
import { toggleClasses } from '@/utils/Common/Utils';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

let TimelineLite;
let Power0;
let gsap;
let CSSPlugin;

export default async () => {
  const entries = SmarteditController.getEditorialEntries(
    '.bra-btnIcon--hamburger-menu',
  );
  const btnMenu = entries[0];

  if (!btnMenu) return;

  ({ TimelineLite, Power0, gsap, CSSPlugin } = await LazyGsap());
  gsap.registerPlugin(CSSPlugin);
  // const btnMenu_controller = btnMenu.getElementsByTagName('input');
  // let btnMenu_open = false;
  // const btnMenu_input = document.getElementsByClassName(
  //   'bra-btnIcon--hamburger-input',
  // )[0];

  const btnMenu__bars_01 = btnMenu.getElementsByTagName('b')[0];
  const btnMenu__bars_02 = btnMenu.getElementsByTagName('b')[1];
  const btnMenu__bars_03 = btnMenu.getElementsByTagName('b')[2];

  const tl__btnMenu = new TimelineLite({
    paused: true,
    reversed: true,
    ease: Power0.easeNone,
    onComplete() {
      btnMenu.classList.remove('animating');
    },
  });

  tl__btnMenu.call(() => {
    toggleClasses(btnMenu, ['animating', 'open']);
  });

  tl__btnMenu.fromTo(btnMenu__bars_01, 0.2, { y: 0 }, { y: '+=8' }, 0);
  tl__btnMenu.fromTo(btnMenu__bars_03, 0.2, { y: 0 }, { y: '-=8' }, 0);
  tl__btnMenu.fromTo(
    btnMenu__bars_03,
    0.2,
    { width: '1em' },
    { width: '0em' },
    0.2,
  );

  tl__btnMenu.fromTo(
    btnMenu__bars_01,
    0.4,
    {
      rotation: 0,
    },
    {
      rotation: 45,
    },
    0.4,
  );
  tl__btnMenu.fromTo(
    btnMenu__bars_02,
    0.4,
    {
      rotation: 0,
    },
    {
      rotation: 135,
    },
    0.4,
  );

  btnMenu.addEventListener('click', () => {
    tl__btnMenu.reversed()
      ? tl__btnMenu.play()
      : tl__btnMenu.play().reversed(true);
  });

  const tl__btnMenuHover = new TimelineLite({
    paused: true,
    ease: Power0.easeNone,
  });

  tl__btnMenuHover.fromTo(
    btnMenu__bars_03,
    0.25,
    {
      width: '0.5em',
    },
    {
      width: '1.01em',
    },
  );

  function over() {
    if (btnMenu.classList.contains('open')) {
    } else {
      tl__btnMenuHover.play();
    }
  }

  function out() {
    if (btnMenu.classList.contains('open')) {
    } else {
      tl__btnMenuHover.reverse();
    }
  }

  btnMenu.addEventListener('mouseenter', () => over());
  btnMenu.addEventListener('mouseleave', () => out());
};
