/* eslint-disable */
import { initCarousel } from '@/braun/Controllers/BraunCarouselController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const initStories = () => {
    const storiesWrappers = document.querySelectorAll('.js-stories-component');

    Array.prototype.forEach.call(storiesWrappers, async function(el, index, array){
      const _this = el;

      let myTimer;

      const animation__bar = function() {
        const slider = _this.querySelectorAll('.stories-slider')[0];
        const totaleSlides = slider.querySelectorAll('.swiper-slide').length;
        const activeSlide = slider.querySelectorAll('.swiper-slide-active')[0];
        const allSlides = slider.querySelectorAll('.swiper-slide');
        const activeSlide_index = [].indexOf.call(
          activeSlide.parentElement.children,
          activeSlide,
        );

        if (activeSlide_index == totaleSlides - 1) {
          storiesSlider.autoplay.stop();


          if (myTimer == null) {
            myTimer = setTimeout(() => {
              _this.classList.remove('running');
              _this.classList.add('end');

              const bullets = _this.querySelectorAll(
                '.swiper-pagination-bullet',
              );
              for (const bullet of bullets) {
                bullet.classList.remove('full');
                bullet.classList.remove('current');
              }

              const slides = _this.querySelectorAll('.swiper-slide');
              for (const slide of slides) {
                slide.classList.remove('swiper-slide-active');
              }
            }, 8000);
          }
        } else {
          if (myTimer != null) {
            clearTimeout(myTimer);
            myTimer = null;
          }
          storiesSlider.autoplay.start();
        }

        Array.prototype.forEach.call(allSlides, (el, i) => {
          el.classList.remove('current');
        });
        activeSlide.classList.add('current');

        for (let i = 0; i < activeSlide_index; i++) {
          const thisBullet = _this.querySelectorAll(
            `.swiper-pagination-bullet--${i}`,
          )[0];
          thisBullet.classList.add('full');
          thisBullet.classList.remove('current');
        }
        for (let i = activeSlide_index + 1; i < totaleSlides; i++) {
          const thisBullet = _this.querySelectorAll(
            `.swiper-pagination-bullet--${i}`,
          )[0];
          thisBullet.classList.remove('full');
          thisBullet.classList.remove('current');
        }

        const thisBullet = _this.querySelectorAll(
          `.swiper-pagination-bullet--${activeSlide_index}`,
        )[0];
        thisBullet.classList.add('current');
      };

      const storiesSliderElement = document.getElementsByClassName(
        'stories-slider',
      )[0];

      const storiesSlider = await initCarousel(
        storiesSliderElement,
        {
          slidesPerViewXl: 'auto',
          slidesPerViewL: 'auto',
          slidesPerViewM: 'auto',
          slidesPerViewXs: 'auto',
        },
        {
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
          watchOverflow: true,
          roundLengths: true,
          disableOnInteraction: false,
          stopOnLastSlide: true,
          on: {
            init() {
              _this.classList.add('ready');
            },
            transitionEnd() {
              animation__bar();
            },
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
              let slide = this.$el[0].querySelectorAll('.swiper-slide')[index];
              return '<span class="' + className + ' ' + className + '--'  + index + '"><i></i></span>';
            },
          },
          autoplay: {
            delay: 8000,
          },
        },
      );

      storiesSlider.autoplay.stop();

      const btn_start = _this.querySelectorAll(
        '.bra-stories__screen--start',
      )[0];
      btn_start.addEventListener('click', e => {
        e.preventDefault();

        _this.classList.add('running');

        const bullets = _this.querySelectorAll('.swiper-pagination-bullet');
        for (const bullet of bullets) {
          bullet.classList.remove('full');
          bullet.classList.remove('current');
        }

        storiesSlider.slideTo(0, 0);
        storiesSlider.autoplay.start();

        animation__bar();
        clearTimeout(myTimer);
        myTimer = null;
      });

      const btn_end = _this.querySelectorAll('.bra-stories__screen--end')[0];
      btn_end.addEventListener('click', e => {
        e.preventDefault();

        _this.classList.add('running');
        _this.classList.remove('end');

        const bullets = _this.querySelectorAll('.swiper-pagination-bullet');
        for (const bullet of bullets) {
          bullet.classList.remove('full');
          bullet.classList.remove('current');
        }

        storiesSlider.slideTo(0, 0);
        storiesSlider.autoplay.start();
        animation__bar();
        clearTimeout(myTimer);
        myTimer = null;
      });

      const btn_close = _this.querySelectorAll('.swiper-button-close')[0];
      btn_close.addEventListener('click', () => {
        _this.classList.add('ready');
        _this.classList.remove('running');

        const bullets = _this.querySelectorAll('.swiper-pagination-bullet');
        for (const bullet of bullets) {
          bullet.classList.remove('full');
          bullet.classList.remove('current');
        }

        const slides = _this.querySelectorAll('.swiper-slide');
        for (const slide of slides) {
          slide.classList.remove('swiper-slide-active');
        }

        storiesSlider.autoplay.stop();
        clearTimeout(myTimer);
        myTimer = null;
      });
    });
  };
  initStories();



  const initStoriesComponent = () => {
    initStories();
  };
  SmarteditController.addOnReprocessPageListener(initStoriesComponent);
  initStoriesComponent();
};
