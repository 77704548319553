/* eslint-disable no-new */

// import gtm
import { bindGtmEventsForPlyrPlayer } from '@/utils/Controllers/GTM/Operations/Video';
import { LazyPlyrWhenVisible } from '@/utils/Controllers/LazyPlyrController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

class Player {
  constructor(el, Plyr) {
    this.Plyr = Plyr;
    this.DOM = { el };
    this.DOM.videoContainer = this.DOM.el.querySelector(
      '.bra-videoBanner__player',
    );
    this.DOM.videoFrame = this.DOM.videoContainer.querySelector('.js-player');
    this.DOM.playButton = this.DOM.el.querySelector('.bra-videoBanner__link');
    this.DOM.closeButton = this.DOM.videoContainer.querySelector(
      '.bra-btnIcon--close',
    );

    this.initEvents();
  }

  initEvents() {
    const self = this;

    const plyrController = new this.Plyr(self.DOM.videoFrame);
    const gtmInfo = {
      videoTitle: self.DOM.videoContainer.dataset.gtmTitle,
      videoId: self.DOM.videoContainer.dataset.gtmVideoId,
      videoUrl: self.DOM.videoContainer.dataset.gtmVideoUrl,
      howTo: '',
    };
    bindGtmEventsForPlyrPlayer(plyrController, gtmInfo);

    self.DOM.videoFrame.addEventListener('ready', () => {
      self.DOM.el.classList.add('ready');
    });

    self.DOM.playButton.addEventListener('click', event => {
      event.preventDefault();
      self.DOM.videoContainer.classList.add('visible');
      plyrController.play();
    });

    self.DOM.closeButton.addEventListener('click', event => {
      event.preventDefault();
      self.DOM.videoContainer.classList.remove('visible');
      plyrController.stop();
    });
  }
}

const init = async () => {
  const videos = SmarteditController.getEditorialEntries('.js-bra-videoBanner');
  if (videos.length > 0) {
    LazyPlyrWhenVisible(videos, (target, PlyrLib) => {
      new Player(target, PlyrLib);
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
