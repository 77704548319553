import SmarteditController from '@/utils/Controllers/SmarteditController';

const ACCOUNT_ICON_WRAPPER_SELECTOR = '.js-braun-header-account-wrapper';

export default () => {
  class Item {
    constructor(el) {
      this.DOM = { el };
      this.DOM.controller = this.DOM.el.querySelector('input');
      this.DOM.popOver = this.DOM.el.querySelector('.dln-drawer');
      this.DOM.supportsTouch =
        'ontouchstart' in window || navigator.msMaxTouchPoints;

      this.initEvents();
    }

    initEvents() {
      window.addEventListener('resize', () => this.reflowDrawer());
      window.addEventListener('scroll', () => this.reflowDrawer());

      if (this.DOM.supportsTouch === true) {
        this.DOM.el.classList.add('touch');
        this.DOM.el.addEventListener('change', () => this.onChange());
      } else {
        this.DOM.el.addEventListener('mouseenter', () =>
          this.swap('mouseenter'),
        );
        this.DOM.el.addEventListener('mouseleave', () => this.swap());
      }
    }

    onChange() {
      switch (this.DOM.controller.checked) {
        case true:
          this.DOM.el.classList.add('hover');
          break;
        default:
          this.DOM.el.classList.remove('hover');
          break;
      }

      this.reflowDrawer();
    }

    swap(e) {
      if (e === 'mouseenter') {
        this.DOM.controller.checked = false;
      }

      switch (this.DOM.controller.checked) {
        case true:
          this.DOM.controller.checked = false;
          this.DOM.el.classList.remove('hover');
          break;
        default:
          this.DOM.controller.checked = true;
          this.DOM.el.classList.add('hover');
          break;
      }
      this.reflowDrawer();
    }

    reflowDrawer() {
      const buttonPosition = this.DOM.el.getBoundingClientRect();
      // let drawerPosition = this.DOM.popOver.getBoundingClientRect();
      this.DOM.popOver.style.top = `${buttonPosition.top +
        buttonPosition.height +
        15}px`;
    }
  }

  const entries = SmarteditController.getEditorialEntries(
    ACCOUNT_ICON_WRAPPER_SELECTOR,
  );
  Array.from(entries).forEach(item => new Item(item));
};
