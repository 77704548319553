import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const calculate = endDateString => {
    let startDate = new Date();
    const endDate = new Date(Number(endDateString));

    const days = differenceInDays(endDate, startDate);
    startDate = addDays(startDate, days);

    const hours = differenceInHours(endDate, startDate);
    startDate = addHours(startDate, hours);

    const minutes = differenceInMinutes(endDate, startDate);
    startDate = addMinutes(startDate, minutes);

    const seconds = differenceInSeconds(endDate, startDate);

    document.querySelector('.bra-stage__countdown-days').innerHTML =
      (days > 0 && days) || '00';
    document.querySelector('.bra-stage__countdown-hours').innerHTML =
      (hours > 0 && hours) || '00';
    document.querySelector('.bra-stage__countdown-minutes').innerHTML =
      (minutes > 0 && minutes) || '00';
    document.querySelector('.bra-stage__countdown-seconds').innerHTML =
      (seconds > 0 && seconds) || '00';
  };

  const initCountDown = countdown => {
    const endDate = countdown.getAttribute('data-date-countdown');
    setInterval(() => calculate(endDate), 1000);
  };

  const initFullStage = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.bra-stage__countdown',
    );
    Array.from(entries).forEach(item => initCountDown(item));
  };
  SmarteditController.addOnReprocessPageListener(initFullStage);
  initFullStage();
};
