/* eslint-disable camelcase */
import { checkLet } from '@/braun/Controllers/BraunHeaderController';
import { off, on } from '@/utils/Common/EventsDelegation';
import { toggleClasses } from '@/utils/Common/Utils';
import EVENTS from '@/utils/Constants/Events';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import _debounce from 'lodash/debounce';

let TimelineLite;
let Expo;
let Power0;
let Power1;
let gsap;
let CSSPlugin;

export default async navigationContainer => {
  if (!navigationContainer) return;

  ({ TimelineLite, Expo, Power0, Power1, gsap, CSSPlugin } = await LazyGsap());
  gsap.registerPlugin(CSSPlugin);

  const headerContainer = document.getElementsByClassName('bra-header')[0];

  const isMobileBp = () => window.matchMedia('(max-width:767px)').matches;

  const headerLanguageVisible = !!document.getElementsByClassName(
    'dln-country-stripe',
  ).length;
  const headerLanguage =
    headerLanguageVisible &&
    document.getElementsByClassName('dln-country-stripe')[0];

  const countryStripe = document.querySelector('.dln-country-stripe');

  const btnMenu = headerContainer.getElementsByClassName(
    'bra-btnIcon--hamburger-menu',
  )[0];
  const btnMenu_controller = btnMenu.getElementsByTagName('input')[0];
  let btnMenu_open = false;

  const animatedChild = navigationContainer.querySelectorAll(
    '.bra-navigation__list-container .animatedChild',
  );

  const tl__navigationContainer = new TimelineLite({
    paused: true,
    reversed: true,
    ease: Power0.easeNone,
    immediateRender: false,
    onComplete() {
      navigationContainer.classList.remove('animating');
      const elements = navigationContainer.querySelectorAll(
        '.bra-navigation__list li',
      );
      Array.prototype.forEach.call(elements, el => {
        el.setAttribute('data-offset', el.offsetTop);
      });
    },
    onReverseComplete() {
      headerContainer.classList.remove('bra-header--open');
      navigationContainer.classList.remove('animating');
      checkLet();
    },
  });

  tl__navigationContainer.call(
    () => {
      toggleClasses(navigationContainer, ['animating', 'bra-navigation--open']);
      toggleClasses(headerContainer, 'bra-header--open');
    },
    null,
    '+=.1',
  );

  tl__navigationContainer.addLabel('backwards');

  tl__navigationContainer.set('#bra-navigation__list-spacer', {
    height: headerContainer.offsetHeight,
  });

  const setListSpacerHeight = () => {
    const listSpacerFixed = document.getElementById(
      'bra-navigation__list-spacer--fixed',
    );

    if (countryStripe) {
      if (countryStripe.classList.contains('hide')) {
        listSpacerFixed.style.height = 0;
      } else {
        listSpacerFixed.style.height = headerLanguage.offsetHeight;
      }
    }
  };

  DLG.EVE.on(EVENTS.STRIPE.CLOSED, () => {
    tl__navigationContainer.set('#bra-navigation__list-spacer--fixed', {
      height: 0,
    });
  });

  setListSpacerHeight();
  window.addEventListener(
    'resize',
    _debounce(() => {
      setListSpacerHeight();
    }, 100),
  );

  tl__navigationContainer.staggerFromTo(
    animatedChild,
    0.7,
    {
      x: '-50px',
      opacity: 0,
      force3D: true,
      ease: Expo.easeOut,
    },
    {
      x: '0%',
      opacity: 1,
      force3D: true,
      ease: Expo.easeOut,
      clearProps: 'all',
    },
    0.05,
  );

  tl__navigationContainer.fromTo(
    '.bra-navigation__footer',
    1,
    {
      opacity: '0',
      ease: Power1.easeOut,
    },
    {
      opacity: '1',
      ease: Power1.easeOut,
    },
    '-=0.85',
  );

  function closeAllSubMenu() {
    const navigation_categoryWrapper = navigationContainer.getElementsByClassName(
      'bra-navigation__categoryWrapper',
    )[0];
    navigation_categoryWrapper.classList.remove('active');

    const navigationVisualContainer = document.querySelectorAll(
      '.bra-navigation__visual-container',
    )[0];

    const navigation_list = navigationContainer.getElementsByClassName(
      'bra-navigation__list',
    )[0];
    const navigation_list_items = navigation_list.querySelectorAll(
      '.bra-navigation__list-item',
    );

    Array.prototype.forEach.call(navigation_list_items, el => {
      el.classList.remove('active');
    });

    // const navigation_list_input = navigation_list.querySelectorAll('input');
    Array.prototype.forEach.call(navigation_list_items, el => {
      el.checked = false;
    });

    navigationContainer.classList.remove('active');
    navigationVisualContainer.classList.remove('visible');
  }

  const manageLazyImages = () => {
    Array.from(document.querySelectorAll('.bra-lazyPicture')).forEach(
      picture => {
        picture.classList.remove('bra-lazyPicture');
        Array.from(picture.querySelectorAll('source')).forEach(source => {
          source.setAttribute('srcset', source.dataset.srcset);
        });
        const img = picture.querySelector('img');
        img.src = img.dataset.src;
      },
    );
  };

  btnMenu.addEventListener('change', () => {
    btnMenu_open = btnMenu_controller.checked;
    if (tl__navigationContainer.reversed()) {
      tl__navigationContainer.play();
    } else {
      tl__navigationContainer
        .seek('backwards')
        .play()
        .reversed(true);
    }

    if (btnMenu_open === false) {
      closeAllSubMenu();

      const pageWrapper =
        document.getElementsByClassName('.dln-page')[0] ||
        document.getElementsByClassName('.page__wrapper')[0];

      off('touchstart', pageWrapper);
      off('touchmove', pageWrapper);
      on('touchstart', pageWrapper, () => {});
      on('touchmove', pageWrapper, () => {});

      const pageFooter = document.getElementsByClassName('.bra-footer')[0];

      off('touchstart', pageFooter);
      off('touchmove', pageFooter);
      on('touchstart', pageFooter, () => {});
      on('touchmove', pageFooter, () => {});

      document.body.classList.remove('mobileOpen');
    } else {
      const pageWrapper =
        document.getElementsByClassName('.dln-page')[0] ||
        document.getElementsByClassName('.page__wrapper')[0];

      off('touchstart', pageWrapper);
      off('touchmove', pageWrapper);
      on('touchstart', pageWrapper, e => {
        e.preventDefault();
      });
      on('touchmove', pageWrapper, e => {
        e.preventDefault();
      });

      const pageFooter = document.getElementsByClassName('.bra-footer')[0];

      off('touchstart', pageFooter);
      off('touchmove', pageFooter);
      on('touchstart', pageFooter, e => {
        e.preventDefault();
      });
      on('touchmove', pageFooter, e => {
        e.preventDefault();
      });

      document.body.classList.add('mobileOpen');
      if (!isMobileBp()) {
        manageLazyImages();
      }
    }
  });

  const calcWinsize = () => {
    const navigationOpenWrapper = document.getElementsByClassName(
      'bra-navigation.bra-navigation--open',
    )[0];

    if (navigationOpenWrapper) {
      navigationOpenWrapper.style.height = `${window.innerHeight}px`;
    }
  };

  window.addEventListener('resize', () => {
    if (!isMobileBp()) {
      manageLazyImages();
    }
  });

  window.addEventListener('resize', calcWinsize);
};
