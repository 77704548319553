/* eslint-disable camelcase */
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import _isEmpty from 'lodash/isEmpty';

let TimelineLite;
let Power4;
let Linear;
let gsap;
let CSSPlugin;

const scrollToPage = stageWrapper => {
  window.scrollTo(0, stageWrapper.offsetHeight - 85);
};

const initScrollToPage = stageWrapper => {
  const scrollDownBtn = stageWrapper
    .querySelector('.bra-stage__chevron')
    .querySelector('.bra-btnIcon');
  scrollDownBtn.addEventListener('click', () => scrollToPage(stageWrapper));
};

export default async () => {
  const initStageComponent = async () => {
    const stages = document.querySelectorAll('.bra-stage__wrapper');
    if (_isEmpty(stages)) return;

    ({ TimelineLite, Power4, Linear, gsap, CSSPlugin } = await LazyGsap());
    gsap.registerPlugin(CSSPlugin);
    Array.from(stages).forEach(stageWrapper => {
      const cardWrapper = stageWrapper.querySelector('.bra-stage__cardWrapper');
      // const card = stageWrapper.querySelector('.bra-stage__card');
      const info__heading = cardWrapper.querySelector(
        '.bra-stage__card_info__heading',
      );
      const title = cardWrapper.querySelector('.bra-title');
      const paragraph = cardWrapper.querySelector('.bra-paragraph');
      const cta = cardWrapper.querySelector('.bra-btn');
      const visual = cardWrapper.querySelector('.bra-stage__card_visual');
      const cheveron = stageWrapper.querySelector('.bra-stage__chevron');
      const discalimer = stageWrapper.querySelector(
        '.bra-stage__discalimerWrapper',
      );

      let hasTitle = false;
      let hasParagraph = false;
      let hasCTA = false;
      let hasVisual = false;
      let hasDiscalimer = false;

      if (title != null) {
        hasTitle = true;
      }
      if (paragraph != null) {
        hasParagraph = true;
      }
      if (cta != null) {
        hasCTA = true;
      }
      if (visual != null) {
        hasVisual = true;
      }
      if (discalimer != null) {
        hasDiscalimer = true;
      }

      const tl__loopChevron = new TimelineLite({
        paused: true,
        repeat: -1,
        ease: Power4.easeInOut,
        onComplete() {
          this.restart();
        },
      });

      const tl__openStage = new TimelineLite({
        paused: true,
        ease: Power4.easeInOut,
        onComplete() {
          tl__loopChevron.play();
        },
      });

      tl__openStage.set(info__heading, {
        display: 'none',
        opacity: 0,
      });

      if (hasTitle === true) {
        tl__openStage.set(title, {
          display: 'none',
          opacity: 0,
        });
      }

      if (hasParagraph === true) {
        tl__openStage.set(paragraph, {
          display: 'none',
          opacity: 0,
        });
      }

      if (hasCTA === true) {
        tl__openStage.set(cta, { opacity: 0 });
      }

      if (hasVisual === true) {
        tl__openStage.set(visual, { opacity: 0 });
      }
      tl__openStage.set(cheveron, { opacity: 0 });

      if (hasDiscalimer === true) {
        tl__openStage.set(discalimer, { opacity: 0 });
      }

      tl__openStage.fromTo(
        cardWrapper,
        1,
        {
          opacity: 0,
          scaleX: 0,
          force3D: true,
        },
        {
          opacity: 1,
          scaleX: 1,
          force3D: true,
        },
        0,
      );

      tl__openStage.fromTo(
        info__heading,
        1,
        {
          display: 'block',
          opacity: 0,
          x: '-=30px',
          force3D: true,
        },
        {
          opacity: 1,
          x: 0,
          force3D: true,
        },
        0.75,
      );

      if (hasTitle === true) {
        tl__openStage.fromTo(
          title,
          1,
          {
            display: 'block',
            opacity: 0,
            x: '-=60px',
            force3D: true,
          },
          {
            opacity: 1,
            x: 0,
            force3D: true,
          },
          1,
        );
      }

      if (hasVisual === true) {
        tl__openStage.fromTo(
          visual,
          1,
          {
            display: 'block',
            opacity: 0,
            x: '-=60px',
            force3D: true,
          },
          {
            opacity: 1,
            x: 0,
            force3D: true,
          },
          1.25,
        );
      }

      if (hasParagraph === true) {
        tl__openStage.fromTo(
          paragraph,
          1,
          {
            display: 'block',
            opacity: 0,
            x: '-=60px',
            force3D: true,
          },
          {
            opacity: 1,
            x: 0,
            force3D: true,
          },
          1.25,
        );
      }

      if (hasCTA === true) {
        tl__openStage.fromTo(
          cta,
          1,
          {
            display: 'block',
            opacity: 0,
            x: '-=60px',
            force3D: true,
          },
          {
            opacity: 1,
            x: 0,
            force3D: true,
          },
          1.25,
        );
      }

      if (hasDiscalimer === true) {
        tl__openStage.fromTo(
          discalimer,
          1,
          {
            display: 'block',
            opacity: 0,
            force3D: true,
          },
          {
            opacity: 1,
            force3D: true,
          },
        );
      }

      tl__openStage.fromTo(
        cheveron,
        1,
        {
          display: 'block',
          opacity: 0,
          force3D: true,
        },
        {
          opacity: 1,
          force3D: true,
        },
        '-=1',
      );

      tl__openStage.play();

      tl__loopChevron.to(cheveron, 0.35, {
        y: 10,
        ease: Linear.easeNone,
      });
      tl__loopChevron.to(cheveron, 0.35, {
        y: 0,
        ease: Linear.easeNone,
      });

      tl__loopChevron.to(cheveron, 2, {
        y: 0,
        ease: Linear.easeNone,
      });

      initScrollToPage(stageWrapper);
    });
  };
  initStageComponent();
};
