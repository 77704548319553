/* eslint-disable no-use-before-define */
import React from 'react';
import ReactDOM from 'react-dom';
import SmarteditController from '@/utils/Controllers/SmarteditController';

import MiniCartBraun from '@/components/organisms/MiniCartBraun';
import MiniWishlistBraun from '@/components/organisms/MiniWishlistBraun';

const initHeaderIcons = () => {
  const entries = SmarteditController.getEditorialEntries(
    '#js-r-entry-braun-header',
  );
  Array.from(entries).forEach(entry => {
    ReactDOM.render(
      <>
        <MiniCartBraun />
        <MiniWishlistBraun />
      </>,
      entry,
    );
  });
};

// h-en part
let mustBeTrasparent = false;

export const checkLet = () => {
  const headerContainer = document.getElementsByClassName('bra-header')[0];

  if (Math.abs(window.pageYOffset) < 20) {
    headerContainer.classList.remove('bra-header--shrink');

    if (mustBeTrasparent === true) {
      headerContainer.classList.add('bra-header--transparent');
    } else {
      headerContainer.classList.remove('bra-header--transparent');
    }
  } else {
    headerContainer.classList.add('bra-header--shrink');
    headerContainer.classList.remove('bra-header--transparent');
  }
};

const initHeader = () => {
  let didScroll;
  let st = window.pageYOffset;
  const headerContainer = document.getElementsByClassName('bra-header')[0];

  if (headerContainer.classList.contains('bra-header--transparent')) {
    mustBeTrasparent = true;
  }

  checkLet();

  document.body.classList.add('headerReady');
  document.body.style.setProperty(
    '--headerHeight',
    `${headerContainer.offsetHeight}px`,
  );

  window.onscroll = () => {
    if (didScroll) {
      window.cancelAnimationFrame(didScroll);
    }
    st = window.pageYOffset;
    didScroll = window.requestAnimationFrame(() => {
      hasScrolled();
    });

    if (Math.abs(st) < 2) {
      setTimeout(() => {
        document.body.style.setProperty(
          '--headerHeight',
          `${headerContainer.offsetHeight}px`,
        );
      }, 320);
    }
  };

  window.onresize = () => {
    document.body.classList.add('headerReady');
    if (Math.abs(window.pageYOffset) < 2) {
      setTimeout(() => {
        document.body.style.setProperty(
          '--headerHeight',
          `${headerContainer.offsetHeight}px`,
        );
      }, 320);
    }
  };

  const hasScrolled = () => {
    checkLet();
    // lastScrollTop = st;
  };
};

export default () => {
  initHeaderIcons();
  initHeader();
};
