export default () => {
  const MathUtils = {
    map: (x, a, b, c, d) => ((x - a) * (d - c)) / (b - a) + c,
    lerp: (a, b, n) => (1 - n) * a + n * b,
  };

  // const { body } = document;

  let winsize;
  const calcWinsize = () => {
    winsize = { width: window.innerWidth, height: window.innerHeight };
  };
  calcWinsize();

  window.addEventListener('resize', calcWinsize);

  let docScroll;
  const getPageYScroll = () => {
    docScroll = window.pageYOffset || document.documentElement.scrollTop;
  };
  window.addEventListener('scroll', getPageYScroll);

  class Item {
    constructor(el) {
      this.DOM = { el };
      this.DOM.image = this.DOM.el.querySelector('.bra-image__image');
      this.renderedStyles = {
        innerTranslationY: {
          previous: 0,
          current: 0,
          ease: 0.1,
          maxValue: parseInt(
            getComputedStyle(this.DOM.image).getPropertyValue('--overflow'),
            10,
          ),

          setValue: () => {
            const maxValue = this.renderedStyles.innerTranslationY.maxValue / 2;
            const minValue = -1 * maxValue * 2;
            return Math.max(
              Math.min(
                MathUtils.map(
                  this.props.top - docScroll,
                  winsize.height,
                  -1 * this.props.height,
                  minValue,
                  maxValue,
                ),
                maxValue,
              ),
              minValue,
            );
          },
        },
      };
      this.update();
      this.observer = new IntersectionObserver(entries => {
        Array.from(entries).forEach(entry => {
          this.isVisible = entry.intersectionRatio > 0;
        });
      });
      this.observer.observe(this.DOM.el);
      this.initEvents();

      // console.log(this)
    }

    update() {
      this.getSize();
      Object.keys(this.renderedStyles).forEach(key => {
        const renderedStyle = this.renderedStyles[key];
        renderedStyle.current = renderedStyle.setValue();
        renderedStyle.previous = renderedStyle.setValue();
      });
      this.layout();
    }

    getSize() {
      const rect = this.DOM.el.getBoundingClientRect();
      this.props = {
        height: rect.height,
        top: docScroll + rect.top,
      };
    }

    initEvents() {
      window.addEventListener('resize', () => this.resize());
      this.render();
    }

    resize() {
      this.update();
    }

    render() {
      Object.keys(this.renderedStyles).forEach(key => {
        const renderedStyle = this.renderedStyles[key];
        renderedStyle.current = this.renderedStyles[key].setValue();
        renderedStyle.previous = MathUtils.lerp(
          renderedStyle.previous,
          renderedStyle.current,
          renderedStyle.ease,
        );
      });
      this.layout();
      requestAnimationFrame(() => this.render());
    }

    layout() {
      this.DOM.image.style.transform = `translate3d(0,${this.renderedStyles.innerTranslationY.previous}px,0)`;
    }
  }

  getPageYScroll();

  Array.from(document.querySelectorAll('.parallax')).forEach(
    item => new Item(item),
  );
};
