/* eslint-disable no-new */
import LazySwiperController from '@/utils/Controllers/LazySwiperController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const initCategorySlider = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-category-slider',
    );
    Array.from(entries).forEach(async item => {
      const nextEl = item.querySelector('.swiper-button-next');
      const prevEl = item.querySelector('.swiper-button-prev');
      const scrollbar = item.querySelector('.swiper-scrollbar');

      const LazySwiperLib = await LazySwiperController();
      return new LazySwiperLib(item, {
        spaceBetween: 20,
        slidesPerView: 'auto',
        slidesPerGroup: 3,
        slidesOffsetBefore: 30,
        slidesOffsetAfter: 30,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        roundLengths: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        preloadImages: false,

        breakpoints: {
          577: {
            centeredSlides: false,
            slidesPerView: 'auto',
            spaceBetween: 10,
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            slidesPerGroup: 1,
          },
          991: {
            slidesPerView: 'auto',
            slidesOffsetBefore: 30,
            slidesOffsetAfter: 30,
            slidesPerGroup: 2,
          },
        },
        scrollbar: {
          el: scrollbar,
          hide: false,
        },
        navigation: {
          nextEl,
          prevEl,
        },
        on: {
          transitionEnd() {
            const thisSwiper = this.$wrapperEl[0];
            const allSlides = thisSwiper.getElementsByClassName(
              'bra-card--category',
            );

            for (let i = 0; i < allSlides.length; i += 1) {
              allSlides[i].classList.remove('active');
            }

            const activeSlide = thisSwiper.getElementsByClassName(
              'swiper-slide-active',
            )[0];
            const activeSlideCard = activeSlide.getElementsByClassName(
              'bra-card--category',
            )[0];

            if (activeSlideCard) activeSlideCard.classList.add('active');
          },
        },
      });
    });
  };
  SmarteditController.addOnReprocessPageListener(initCategorySlider);
  initCategorySlider();
};
