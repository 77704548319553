import SmarteditController from '@/utils/Controllers/SmarteditController';

export const initBreadcrumbs = () => {
  const currentItem = document.querySelector('.dln-breadcrumbs__li.active');
  if (currentItem) {
    const siblings = currentItem.previousElementSibling;
    if (siblings) {
      siblings.classList.add('previousActive');
    }
  }
};

export default () => {
  SmarteditController.addOnReprocessPageListener(initBreadcrumbs);
  initBreadcrumbs();
};
