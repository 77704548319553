import { closeAccountDrawer } from '@/braun/Controllers/Header/NavigationDesktopController';
import { isTrue } from '@/utils/Common/Booleans';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import { debounce } from 'lodash';

let gsap;

const CTA_ID = 'js-braun-navigation-mobile-cta';
const MENU_ID = 'js-braun-nav-mobile';
const MASK_SELECTOR = '#js-braun-nav-mobile__mask';
const DRAWER_SELECTOR = '#js-braun-nav-mobile__drawer';
const DRAWER_CONTENT_SELECTOR = '#js-braun-nav-mobile__drawer-content';
const CARDS_HIDDEN_WRAPPER_ID = 'js-braun-header-card-hidden-wrapper';
const NAV_CARDS_WRAPPER_SELECTOR = '#js-braun-nav-mobile__card-wrapper';

const MENU_HEADER_BACK_SELECTOR = '#js-braun-nav-mobile__back-cta';
const MENU_HEADER_CLOSE_SELECTOR = '#js-braun-nav-mobile__close-cta';

const LVL1_UL_SELECTOR = '#js-braun-nav-mobile__lvl1-ul';
const LVL1_CTA_SELECTOR = '.js-braun-nav-mobile__lvl1-cta';
const LVL2_CTA_SELECTOR = '.js-braun-nav-mobile__lvl2-cta';
const LVL3_UL_SELECTOR = '.js-braun-nav-mobile__lvl3-ul';

const NAV_ACCOUNT_DRAWER_SELECTOR = '.js-braun-header-account-wrapper';

const OPEN_CLASS = '--open';

const BP_L = 1024;

let firstSetup = true;
let menuTriggerCta = null;
let menuEl = null;
let maskEl = null;
let drawerEl = null;
let drawerContentEl = null;
let mainTl = null;
let menuHeaderBackCta = null;
let menuHeaderCloseCta = null;
let lvl1Ul = null;
let lvl1Ctas = null;
let lvl2Ctas = null;
let cardsHiddenWrapperEl = null;
let currentFirstLvlUid = null;
let accountDrawer = null;

export const showCardInWrapper = (cardWrapperEl, currentCardUid) => {
  // lets find every card in the wrapper
  const availableCards = cardWrapperEl.children;
  // hide every cards inside wrapper callback
  const hideAllCards = () => {
    Array.from(availableCards).forEach(card => {
      card.style.display = 'none';
      card.dataset.visible = false;
    });
  };

  if (!currentCardUid) {
    hideAllCards();
    return;
  }
  // find next card to show inside the wrapper
  let nextCard = cardWrapperEl.querySelector(`#${currentCardUid}`);
  // if found and already visible, do nothing
  if (nextCard && isTrue(nextCard.dataset.visible)) return;
  // if not found we need to append it inside the wrapper
  if (!nextCard) {
    nextCard = document.getElementById(currentCardUid);
    // if not found
    if (!nextCard) {
      hideAllCards();
      return;
    }
    cardWrapperEl.appendChild(nextCard);
  }
  hideAllCards();
  // show the new card
  nextCard.dataset.visible = true;
  gsap.set(nextCard, { display: 'flex', opacity: 0 });
  gsap.fromTo(
    nextCard,
    { opacity: 0.3, y: -5 },
    { opacity: 1, y: 0, duration: 1 },
  );
};

const lockBody = () => {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'unset';
  closeAccountDrawer();
};

const unlockBody = () => {
  document.body.style.overflow = null;
  document.documentElement.style.overflow = null;
  closeAccountDrawer();
};

const setupElements = () => {
  maskEl = menuEl.querySelector(MASK_SELECTOR);
  drawerEl = menuEl.querySelector(DRAWER_SELECTOR);
  drawerContentEl = menuEl.querySelector(DRAWER_CONTENT_SELECTOR);
  menuHeaderBackCta = menuEl.querySelector(MENU_HEADER_BACK_SELECTOR);
  menuHeaderCloseCta = menuEl.querySelector(MENU_HEADER_CLOSE_SELECTOR);
  lvl1Ul = menuEl.querySelector(LVL1_UL_SELECTOR);
  lvl1Ctas = menuEl.querySelectorAll(LVL1_CTA_SELECTOR);
  lvl2Ctas = menuEl.querySelectorAll(LVL2_CTA_SELECTOR);
  cardsHiddenWrapperEl = document.getElementById(CARDS_HIDDEN_WRAPPER_ID);
  accountDrawer = menuEl.querySelector(NAV_ACCOUNT_DRAWER_SELECTOR);
};

const setCardInWrapper = (cardId, parentCardId) => {
  const cardWrapperEl = menuEl.querySelector(
    `${NAV_CARDS_WRAPPER_SELECTOR}__${currentFirstLvlUid}`,
  );
  if (!cardWrapperEl || !cardsHiddenWrapperEl) return;

  const currentCardId = cardId || parentCardId;

  // show card (method shared with desktop)
  showCardInWrapper(cardWrapperEl, currentCardId);
};

const mainAnimation = () => {
  mainTl = gsap.timeline();
  mainTl
    .set(menuEl, { display: 'block' })
    // little hack to activate the account drawer only after the initial animation, issue visible only on mobile with mouse events
    .set(accountDrawer, { pointerEvents: 'none' })
    .fromTo(maskEl, { opacity: 0 }, { opacity: 1, duration: 0.5 })
    .fromTo(
      drawerEl,
      { left: '100%' },
      { left: 'auto', duration: 0.3 },
      '-=0.5',
    )
    .fromTo(
      drawerContentEl,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 },
      '-=0.3',
    )
    .set(accountDrawer, { pointerEvents: 'all' });
};

const hideLvl2Animation = el => {
  if (!el) return;
  const tl = gsap.timeline();
  tl.to(menuHeaderBackCta, { opacity: 0, x: 20 })
    .to(lvl1Ul, { x: 0, opacity: 1, duration: 0.3 }, '<')
    .to(el, { x: '100%', duration: 0.3 }, '<')
    .set(el, { display: 'none' });
};

const showLvl2Animation = el => {
  const tl = gsap.timeline();
  tl.fromTo(
    menuHeaderBackCta,
    { opacity: 0, x: 20 },
    { opacity: 1, x: 'auto', duration: 0.4 },
  )
    .to(lvl1Ul, { x: '-100%', opacity: 0, duration: 0.2 }, '<')
    .set(el, { display: 'block' }, '<')
    .fromTo(
      el,
      { opacity: 0, x: '100%' },
      { opacity: 1, x: 0, duration: 0.3 },
      '<',
    );
};

const lvl1CtasHandler = e => {
  const target = e.currentTarget;
  const {
    dataset: { uid, cardUid },
  } = target;
  target.classList.toggle(OPEN_CLASS);
  const lvl2Wrapper = menuEl.querySelector(`[data-parent-uid='${uid}']`);

  // set current 1st lvl uid
  currentFirstLvlUid = uid;

  // set card component
  setCardInWrapper(cardUid);

  showLvl2Animation(lvl2Wrapper);
};

const hideLvl3Animation = cta => {
  const tl = gsap.timeline();
  const ulEl = cta.parentElement.querySelector(LVL3_UL_SELECTOR);
  cta.classList.remove(OPEN_CLASS);
  tl.to(ulEl, { opacity: 0, x: -20, duration: 0.2 }).to(ulEl, {
    height: 0,
    marginTop: 0,
    visibility: 'hidden',
    duration: 0.3,
  });
};

const showLvl3Animation = cta => {
  // close other accordions
  Array.from(lvl2Ctas).forEach(curr => {
    if (curr.id !== cta.id && curr.classList.contains(OPEN_CLASS)) {
      hideLvl3Animation(curr);
    }
  });
  const tl = gsap.timeline();
  const ulEl = cta.parentElement.querySelector(LVL3_UL_SELECTOR);
  cta.classList.add(OPEN_CLASS);
  tl.to(ulEl, {
    height: 'auto',
    marginTop: '20px',
    visibility: 'visible',
    duration: 0.3,
  }).fromTo(ulEl, { opacity: 0, x: -10 }, { opacity: 1, x: 0, duration: 0.2 });
};

const lvl2CtasHandler = e => {
  const cta = e.currentTarget;
  const {
    dataset: { cardUid, parentCardUid },
  } = cta;
  const isOpen = cta.classList.contains(OPEN_CLASS);
  if (isOpen) {
    hideLvl3Animation(cta);
    setCardInWrapper(parentCardUid); // we are closing the menu so set the parent card
  } else {
    showLvl3Animation(cta);
    setCardInWrapper(cardUid, parentCardUid);
  }
};

const backToLvl1Handler = () => {
  const lvl2Wrapper = menuEl.querySelector(
    `[data-parent-uid='${currentFirstLvlUid}']`,
  );
  currentFirstLvlUid = null;
  hideLvl2Animation(lvl2Wrapper);
  // closing menu: close all accordions
  Array.from(lvl2Ctas).map(hideLvl3Animation);
};

const hideMenu = () => {
  mainTl.reverse().then(() => {
    // unset body lock
    unlockBody();
    // closing menu: close all accordions
    Array.from(lvl2Ctas).map(hideLvl3Animation);
    // closing menu: back to 1st level
    backToLvl1Handler();
  });
};

const menuResizeHandler = () => {
  const isDesktopMQ = window.matchMedia(`(min-width: ${BP_L}px)`);
  if (isDesktopMQ.matches) {
    menuEl.style.display = 'none';
    hideMenu();
  }
};

const showMenu = () => {
  // block body
  lockBody();

  if (!firstSetup) {
    mainAnimation();
  } else {
    firstSetup = false;
    setupElements();

    // execute main animation
    mainAnimation();

    maskEl.addEventListener('click', hideMenu);
    menuHeaderCloseCta.addEventListener('click', hideMenu);
    menuHeaderBackCta.addEventListener('click', backToLvl1Handler);
    window.addEventListener('resize', debounce(menuResizeHandler, 200));

    Array.from(lvl1Ctas).map(cta =>
      cta.addEventListener('click', lvl1CtasHandler),
    );

    Array.from(lvl2Ctas).map(cta =>
      cta.addEventListener('click', lvl2CtasHandler),
    );
  }
};

const initNavigation = async () => {
  menuTriggerCta = document.getElementById(CTA_ID);
  menuEl = document.getElementById(MENU_ID);
  if (!menuTriggerCta || !menuEl) return;
  ({ gsap } = await LazyGsap());
  menuTriggerCta.addEventListener('click', showMenu);
};

export default () => {
  initNavigation();
};
