import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useIterateModifiers from '@/hooks/useIterateModifiers';

const ButtonCartBraun = ({
  modifiers,
  glyph,
  counter,
  linkUrl,
  linkText,
  onClick,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  drawerKeyDown,
  drawerIsVisible,
  Drawer,
}) => {
  const drawerRef = useRef(null);
  const tagRef = useRef(null);
  const [xPos, setXPos] = useState(0);
  const [opacity, setOpacity] = useState(false);
  const modifiersString = useIterateModifiers('bra-btnIcon--', modifiers);
  const Tag = linkUrl ? 'a' : 'button';
  const { t } = useTranslation();

  useEffect(() => {
    setOpacity(drawerIsVisible);
    // calculate distance of the fixed drawer
    const drawerRect = drawerRef?.current?.getBoundingClientRect();
    const iconRect = tagRef?.current?.getBoundingClientRect();
    if (iconRect) {
      setXPos(iconRect.left + iconRect.width / 2 - (drawerRect.width - 14));
    }
    if (drawerIsVisible) {
      drawerRef.current.focus();
      drawerRef.current.addEventListener('keydown', drawerKeyDown);
    } else if (drawerRef.current.getAttribute('listener')) {
      drawerRef.current.removeEventListener('keydown');
    }
  }, [drawerIsVisible]);

  return (
    <div
      onMouseEnter={onMouseEnter || null}
      onMouseLeave={onMouseLeave || null}
    >
      <Tag
        ref={tagRef}
        className={`bra-btnIcon ${modifiersString} ${
          drawerIsVisible ? 'displayNotificationDrawer' : ''
        }`}
        href={linkUrl || null}
        onClick={onClick || null}
        onKeyDown={onKeyDown || null}
        aria-label={t('cart:cart.page.title')}
      >
        <span className='bra-btnIcon__wrap'>
          <i className={`bra-icn bra-icn__${glyph}`} />
          {counter >= 1 && (
            <span className='bra-btnIcon__checkoutCounter'>
              <i className='bra-btnIcon__checkoutCounterText'>{counter}</i>
            </span>
          )}
        </span>
        {linkText && <span className='bra-btnIcon__text'>{linkText}</span>}
      </Tag>
      <div
        style={{
          display: drawerIsVisible ? '' : 'none',
          opacity: opacity ? '1' : '0',
          left: `${xPos}px`,
        }}
        ref={drawerRef}
        className='bra-miniCartWrapper'
      >
        <Drawer />
      </div>
    </div>
  );
};

ButtonCartBraun.propTypes = {
  modifiers: PropTypes.arrayOf(PropTypes.string),
  glyph: PropTypes.string,
  counter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  onClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  drawerKeyDown: PropTypes.func,
  drawerIsVisible: PropTypes.bool,
  Drawer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
};

ButtonCartBraun.defaultProps = {
  modifiers: [],
  glyph: null,
  counter: null,
  linkUrl: null,
  linkText: null,
  onClick: null,
  onKeyDown: null,
  onMouseEnter: null,
  onMouseLeave: null,
  drawerKeyDown: null,
  drawerIsVisible: false,
  Drawer: null,
};

export default ButtonCartBraun;
