import GenericIntersectionObserver from '@/utils/Controllers/GenericIntersectionObserver';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import _isEmpty from 'lodash/isEmpty';

const SELECTOR = '.js-bra-interactiveBlock';

const loadController = async item => {
  const controller = await import(
    /* webpackChunkName: "InteractiveBlockController-lib" */ '@/braun/Controllers/InteractiveBlock/InteractiveBlockController'
  );
  controller.default(item);
};

const init = () => {
  const entries = SmarteditController.getEditorialEntries(SELECTOR);
  if (!_isEmpty(entries)) {
    GenericIntersectionObserver(entries, target => {
      const image = target.querySelector('img');
      if (image.complete) loadController(target);
      else {
        image.addEventListener('load', () => {
          loadController(target);
        });
      }
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
