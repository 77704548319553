/* eslint-disable camelcase */
import BraunNavigationController from '@/braun/Controllers/BraunNavigationController';
import LazyGsap from '@/utils/Controllers/LazyGsapController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

let TimelineLite;
let Power0;
let gsap;
let CSSPlugin;

export default async () => {
  // const selected = 0;
  let lastCategoryHover = null;
  let lastCategoryOpen = null;
  let isMobile = null;

  if (window.innerWidth <= 599) {
    isMobile = true;
  } else {
    isMobile = false;
  }

  const entries = SmarteditController.getEditorialEntries('.bra-navigation');
  const navigationWrapper = entries[0];

  if (!navigationWrapper) return;

  ({ TimelineLite, Power0, gsap, CSSPlugin } = await LazyGsap());
  gsap.registerPlugin(CSSPlugin);

  const navigationList = navigationWrapper.getElementsByClassName(
    'bra-navigation__list',
  );

  const navigationVisual = navigationWrapper.getElementsByClassName(
    'bra-navigation__visual-container',
  )[0];

  function onMouse(status, elem) {
    if (status === 'enter') {
      Array.prototype.forEach.call(navigationList, el => {
        el.classList.add('faded');
      });

      navigationVisual.classList.add('visible');

      // const openElem = navigationWrapper.querySelectorAll(
      //   '.bra-navigation__list-item.active',
      // );

      const visuals = navigationWrapper.querySelectorAll(
        '.bra-navigation__visual-categoryItem',
      );
      Array.prototype.forEach.call(visuals, el => {
        el.classList.remove('visible');
      });

      lastCategoryHover = elem.getAttribute('data-category');
      const thisCat = navigationWrapper.querySelectorAll(
        `.bra-navigation__visual-categoryItem[data-category="${lastCategoryHover}"]`,
      )[0];
      if (thisCat) {
        thisCat.classList.add('visible');
      }
    } else if (status === 'leave') {
      Array.prototype.forEach.call(navigationList, el => {
        el.classList.remove('faded');
      });

      navigationVisual.classList.remove('visible');
    }
  }

  if (isMobile !== true) {
    Array.prototype.forEach.call(navigationList, el => {
      const navigationList_li = el.getElementsByClassName(
        'bra-navigation__list-item',
      );

      Array.prototype.forEach.call(navigationList_li, elInner => {
        elInner.addEventListener('mouseenter', () => onMouse('enter', elInner));
        elInner.addEventListener('mouseleave', () => onMouse('leave', elInner));
      });
    });
  }

  const animation__openCategory = categoryClicked => {
    const thisCategory = categoryClicked[0];
    lastCategoryOpen = thisCategory;
    const thisCategoryDATA = thisCategory.getAttribute('data-category');
    const categoryWrapper = thisCategory.parentNode.querySelectorAll(
      `.bra-navigation__categoryWrapper[data-category=${thisCategoryDATA}]`,
    )[0];

    const categoryElements = categoryWrapper.querySelectorAll(
      '.bra-navigation__category-item',
    );

    thisCategory.classList.add('active');
    categoryWrapper.classList.add('active');
    navigationWrapper.classList.add('active');
    thisCategory.querySelectorAll('input[type=checkbox]')[0].checked = true;

    const tl__showStaggerCategory = new TimelineLite({
      paused: true,
      ease: Power0.easeNone,
      onComplete() {},
    });

    if (isMobile) {
      tl__showStaggerCategory.fromTo(
        categoryWrapper,
        0.5,
        {
          x: '-10px',
          marginBottom: 0,
          height: 0,
          opacity: 0,
          force3D: true,
        },
        {
          x: '0%',
          height: 'auto',
          marginBottom: '30px',
          opacity: 1,
          force3D: true,
        },
        0,
      );

      tl__showStaggerCategory.fromTo(
        categoryElements,
        0.4,
        {
          x: '0px',
          opacity: 0,
          force3D: true,
        },
        {
          x: '10px',
          opacity: 1,
          force3D: true,
        },
        0.3,
      );
    } else {
      tl__showStaggerCategory.fromTo(
        categoryWrapper,
        0.5,
        {
          x: '-10px',
          marginBottom: 0,
          height: 0,
          opacity: 0,
          force3D: true,
        },
        {
          x: '0%',
          height: 'auto',
          marginBottom: '40px',
          opacity: 1,
          force3D: true,
        },
        0,
      );

      tl__showStaggerCategory.staggerFromTo(
        categoryElements,
        0.4,
        {
          x: '-10px',
          opacity: 0,
          force3D: true,
        },
        {
          x: '0',
          opacity: 1,
          force3D: true,
        },
        0.15,
        0.25,
      );
    }

    tl__showStaggerCategory.play();
  };

  const animation__closeCategory = (categoryClicked, isAll) => {
    let thisCategory;

    if (isAll === true) {
      thisCategory = categoryClicked;
    } else {
      [thisCategory] = categoryClicked;
    }

    const thisCategoryDATA = thisCategory.getAttribute('data-category');
    const categoryWrapper = thisCategory.parentNode.querySelectorAll(
      `.bra-navigation__categoryWrapper[data-category=${thisCategoryDATA}]`,
    )[0];

    // const categoryElements = categoryWrapper.querySelectorAll(
    //   '.bra-navigation__category-item',
    // );

    const tl__hideStaggerCategory = new TimelineLite({
      paused: true,
      ease: Power0.easeNone,
      onComplete() {
        thisCategory.classList.remove('active');
        categoryWrapper.classList.remove('active');
        thisCategory.querySelectorAll(
          'input[type=checkbox]',
        )[0].checked = false;
        navigationWrapper.classList.remove('active');

        const thisCat = navigationWrapper.querySelectorAll(
          `.bra-navigation__visual-categoryItem[data-category="${lastCategoryHover}"]`,
        )[0];
        if (thisCat) {
          thisCat.classList.add('visible');
        }

        setTimeout(() => {
          navigationVisual.classList.add('visible');
        }, 360);
      },
    });

    tl__hideStaggerCategory.fromTo(
      categoryWrapper,
      0.2,
      {
        x: '0%',
        height: 'auto',
        marginBottom: '60px',
        opacity: 1,
        force3D: true,
      },
      {
        x: '-10px',
        marginBottom: 0,
        height: 0,
        opacity: 0,
        force3D: true,
      },
      0,
    );
    tl__hideStaggerCategory.play();

    return tl__hideStaggerCategory.duration();
  };

  const myNavItems = navigationWrapper.querySelectorAll(
    '.bra-navigation__list-item',
  );

  Array.from(myNavItems).forEach(Item => {
    Item.addEventListener('click', e => {
      e.preventDefault();

      const thisInput = Item.querySelectorAll('input[type=checkbox]')[0];
      const thisID = thisInput.id;

      const categoryClicked = navigationWrapper.querySelectorAll(
        `.bra-navigation__list-item[data-category=${thisID}]`,
      );
      // const allCategory = navigationWrapper.querySelectorAll(
      //   `.bra-navigation__list-item:not([data-category=${thisID}])`,
      // );

      // let open = null;

      if (thisInput.checked === true) {
        // open = false;
        thisInput.checked = false;
        animation__closeCategory(categoryClicked);
      } else {
        // open = true;
        thisInput.checked = true;

        if (
          navigationWrapper.querySelectorAll(
            '.bra-navigation__categoryWrapper.active',
          ).length > 0
        ) {
          animation__closeCategory(lastCategoryOpen, true);
          navigationVisual.classList.remove('visible');

          setTimeout(() => {
            animation__openCategory(categoryClicked);
          }, 360);
        } else {
          animation__openCategory(categoryClicked);
        }
      }
    });
  });

  BraunNavigationController(navigationWrapper);
};
