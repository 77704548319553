import { initCarousel } from '@/braun/Controllers/BraunCarouselController';

export default (contentSlider, isPrimary) => {
  if (contentSlider && isPrimary) {
    initCarousel(
      contentSlider,
      {
        slidesPerViewXl: 'auto',
        slidesPerViewL: 'auto',
        slidesPerViewM: 'auto',
        slidesPerViewXs: 'auto',

        spaceBetweenXl: 20,
        spaceBetweenL: 20,
        spaceBetweenM: 20,
        spaceBetweenXs: 10,

        slidesOffsetBeforeXl: 30,
        slidesOffsetBeforeL: 30,
        slidesOffsetBeforeM: 30,
        slidesOffsetBeforeXs: 0,

        slidesOffsetAfterXl: 30,
        slidesOffsetAfterL: 30,
        slidesOffsetAfterM: 30,
        slidesOffsetAfterXs: 0,

        centeredSlidesXs: true,

        hideScrollbar: false,
      },
      {
        roundLengths: true,
        centeredSlides: true,
        preloadImages: false,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet(index, className) {
            if (window.innerWidth >= 768) {
              const slider = this.$el[0];
              const slide = slider.querySelectorAll('.swiper-slide')[index];
              const navTitleText = slide.getAttribute('data-navtitle');

              return `<span class="${className}">${navTitleText}</span>`;
            }
            return '';
          },
        },
      },
    );
  } else if (contentSlider && !isPrimary) {
    initCarousel(
      contentSlider,
      {
        slidesPerViewXl: 'auto',
        slidesPerViewL: 'auto',
        slidesPerViewM: 'auto',
        slidesPerViewXs: 'auto',

        spaceBetweenXl: 20,
        spaceBetweenL: 20,
        spaceBetweenM: 20,
        spaceBetweenXs: 10,

        slidesPerGroupXl: 3,
        slidesPerGroupL: 2,
        slidesPerGroupM: 1,
        slidesPerGroupXs: 1,

        hideScrollbar: false,
      },
      {
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
        roundLengths: true,
        preloadImages: false,
      },
    );
  }
};
