import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const swapTitle = () => {
    const entries = SmarteditController.getEditorialEntries(
      '.js-bra-movingLettersContainer',
    );
    Array.from(entries).forEach(item => {
      const movingTitles = item.querySelectorAll(
        '.bra-movingLetters__title--moving',
      );
      const movingTitlesNumber = [...movingTitles].length;
      let movingTitlesCount = 0;
      setInterval(() => {
        [...movingTitles].forEach(title => {
          if (
            title.classList.contains('bra-movingLetters__title--moving--active')
          ) {
            title.classList.remove('bra-movingLetters__title--moving--active');
          }
        });
        [...movingTitles][movingTitlesCount].classList.add(
          'bra-movingLetters__title--moving--active',
        );
        if (movingTitlesCount < movingTitlesNumber - 1) {
          movingTitlesCount += 1;
        } else {
          movingTitlesCount = 0;
        }
      }, 1000);
    });
  };
  SmarteditController.addOnReprocessPageListener(swapTitle);
  swapTitle();
};
