import Logger from '../Logger';

export default (entriesToObserve, callback) => {
  const observerOptions = {
    rootMargin: '0px 0px 60% 0px',
  };
  const observerCallback = async (entries, observer) => {
    entries.forEach(async entry => {
      try {
        if (!entry.isIntersecting) return;
        observer.unobserve(entry.target);
        callback(entry.target);
      } catch (e) {
        Logger.error(e);
        Logger.error(
          'LazyLoad::Something went wrong processing the following target:',
          entry.target,
        );
      }
    });
  };
  const observer = new IntersectionObserver(observerCallback, observerOptions);
  Array.from(entriesToObserve).forEach(el => {
    observer.observe(el);
  });
};
