import PrimaryContentSliderController from '@/utils/Controllers/PrimaryContentSliderController';
import SmarteditController from '@/utils/Controllers/SmarteditController';

export default () => {
  const initContentSliders = () => {
    const entriesPrimarySlider = SmarteditController.getEditorialEntries(
      '.content-primary-slider',
    );
    Array.from(entriesPrimarySlider).forEach(slider => {
      PrimaryContentSliderController(slider, true);
    });
  };
  SmarteditController.addOnReprocessPageListener(initContentSliders);
  initContentSliders();
};
