import Logger from '@/utils/Logger';

let simplebarLib;

const LazySimplebar = async () => {
  try {
    if (!simplebarLib) {
      await import(
        /* webpackChunkName: "simplebar-css-lib" */ 'simplebar/dist/simplebar.css'
      );
      const data = await import(
        /* webpackChunkName: "simplebar-lib" */ 'simplebar'
      );
      simplebarLib = data.default;
    }
  } catch (e) {
    Logger.error(e);
    Logger.error('LazyLoad::Something went wrong loading Simplebar library');
  }
  return simplebarLib;
};

export default LazySimplebar;
